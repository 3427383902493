<template>
  <div class="contenu-facture-libre">
    <loader v-if="loaderComponent" />
    <div class="body-box-rapport scroll-bar">
      <form @submit.prevent.stop="submitFactureLibre">
        <div class="header-content">
          <v-row>
            <v-col cols="2">
              <v-select
                placeholder="Séléctionnez"
                :items="computedPermissionType"
                class="select-menu"
                label="Type *"
                :persistent-placeholder="true"
                v-model="facture.type"
                outlined
                dense
                :auto-focus="false"
                :menu-props="{ bottom: true, offsetY: true }"
                color="#704ad1"
                item-color="#704ad1"
              >
              </v-select>
            </v-col>
            <v-col cols="2">
              <v-menu :close-on-content-click="true" offset-y>
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="facture.date_creation"
                    label="Date de création"
                    outlined
                    readonly
                    hide-details
                    v-on="on"
                    required
                    :min="minDateForAvoir"
                    :max="maxDate"
                    :disabled="!checkPermission('FLDFAC')"
                    :persistent-placeholder="true"
                    placeholder="Sélectionner un date"
                    prepend-inner-icon="mdi-calendar"
                    :autofocus="false"
                    color="#704ad1"
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="#704ad1"
                  header-color="#704ad1"
                  event-color="704ad1"
                  v-model="facture.date_creation"
                  no-title
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="Lieu de création"
                :persistent-placeholder="true"
                v-model="facture.lieu_creation"
                outlined
                color="#704ad1"
              >
              </v-text-field>
            </v-col>
            <v-col cols="2"> </v-col>
            <v-col cols="2">
              <v-autocomplete
                placeholder="Séléctionnez"
                :items="getAllCategories"
                class="select-menu"
                label="Catégories"
                :persistent-placeholder="true"
                v-model="facture.categorie_id"
                :disabled="computedCheckTypeFactureAvoir"
                :menu-props="{ bottom: true, offsetY: true }"
                item-value="id"
                item-text="nom_categorie"
                no-data-text="Aucune catégorie"
                outlined
                dense
                :auto-focus="false"
                color="#704ad1"
                item-color="#704ad1"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </div>
        <div class="content-card">
          <div
            class="card-style table-rapport-style table-rapport-style-societe"
          >
            <div v-if="facture && facture.vendeur">
              <v-row>
                <v-col cols="9" class="card-name">Fournisseur</v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-autocomplete
                    color="#704ad1"
                    item-color="#704ad1"
                    v-model="facture.vendeur.idOrigine"
                    :items="
                      getFiliaeOfResponsable
                        .map(i => {
                          return {
                            ...i,
                            full_name:
                              i.abreviation == null
                                ? i.name + `(pas d'abréviation)`
                                : i.name,
                            $isDisabled: i.abreviation == null ? true : false
                          };
                        })
                        .filter(item => item.type != 'organisme')
                    "
                    @change="
                      facture.vendeur.id = $event;
                      vendeurSelected();
                    "
                    placeholder="Séléctionnez"
                    :persistent-placeholder="true"
                    label="Société"
                    item-text="full_name"
                    required
                    item-value="id"
                    :auto-focus="false"
                    no-data-text="Aucune société trouvée"
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    placeholder="Séléctionnez"
                    :items="getTypeSociete"
                    class="select-menu"
                    label="Type Société"
                    item-text="type"
                    :menu-props="{ bottom: true, offsetY: true }"
                    item-value="type"
                    no-data-text="Aucun type trouvé"
                    color="#704ad1"
                    item-color="#704ad1"
                    :persistent-placeholder="true"
                    v-model="facture.vendeur.type_societe"
                    outlined
                    dense
                    :auto-focus="false"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-select
                    placeholder="Séléctionnez"
                    :items="num_tva_siren"
                    class="select-menu"
                    label="TVA ou SIRET"
                    item-text="type"
                    item-value="type"
                    :persistent-placeholder="true"
                    v-model="facture.vendeur.siren_tva"
                    outlined
                    color="#704ad1"
                    item-color="#704ad1"
                    :menu-props="{ bottom: true, offsetY: true }"
                    required
                    dense
                    :auto-focus="false"
                  >
                  </v-select>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-if="
                      facture &&
                        facture.vendeur &&
                        facture.vendeur.siren_tva === 'Numéro TVA'
                    "
                    label="Numéro"
                    :persistent-placeholder="true"
                    v-model="facture.vendeur.tva_value"
                    required
                    outlined
                    color="#704ad1"
                  ></v-text-field>
                  <v-text-field
                    v-if="
                      facture &&
                        facture.vendeur &&
                        facture.vendeur.siren_tva === 'SIREN'
                    "
                    label="Numéro"
                    :persistent-placeholder="true"
                    v-model="facture.vendeur.siren_value"
                    required
                    outlined
                    color="#704ad1"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    outlined
                    label="N° et nom de rue *"
                    v-model="facture.vendeur.adresse"
                    :persistent-placeholder="true"
                    rows="3"
                    required
                    color="#704ad1"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="Code Postal *"
                    :persistent-placeholder="true"
                    v-model="facture.vendeur.code_postal"
                    required
                    outlined
                    color="#704ad1"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Ville *"
                    :persistent-placeholder="true"
                    v-model="facture.vendeur.ville"
                    required
                    outlined
                    color="#704ad1"
                  ></v-text-field>
                </v-col>
              </v-row>
              <button
                v-if="!hideVendeur"
                class="more_less"
                @click="showMoreVendeur"
                type="button"
              >
                Plus de détails
                <font-awesome-icon icon="angle-down" class="mt-1 icon-color-normal" />
              </button>

              <div v-if="hideVendeur">
                <v-row>
                  <v-col cols="6">
                    <v-select
                      placeholder="Séléctionnez"
                      :items="uniqueArrayOfCountries"
                      class="select-menu"
                      label="Pays"
                      item-text="nom"
                      required
                      item-value="nom"
                      no-data-text="Aucun pays trouvé"
                      :persistent-placeholder="true"
                      v-model="facture.vendeur.pays"
                      outlined
                      dense
                      :menu-props="{ bottom: true, offsetY: true }"
                      color="#704ad1"
                      item-color="#704ad1"
                      :auto-focus="false"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Siret *"
                      :persistent-placeholder="true"
                      v-model="facture.vendeur.siret"
                      required
                      outlined
                      color="#704ad1"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      label="Adresse Mail"
                      :persistent-placeholder="true"
                      v-model="facture.vendeur.email"
                      required
                      :rules="emailRules"
                      outlined
                      color="#704ad1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Site internet"
                      :persistent-placeholder="true"
                      v-model="facture.vendeur.site_internet"
                      outlined
                      color="#704ad1"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      label="Fax"
                      :persistent-placeholder="true"
                      v-model="facture.vendeur.fax"
                      outlined
                      color="#704ad1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      label="Téléphone"
                      :persistent-placeholder="true"
                      v-model="facture.vendeur.telephone"
                      color="#704ad1"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      outlined
                      label="Description additionnelle"
                      v-model="facture.vendeur.description"
                      :persistent-placeholder="true"
                      rows="3"
                      color="#704ad1"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </div>
              <button
                v-if="hideVendeur"
                class="more_less"
                @click="showMoreVendeur"
                type="button"
              >
                moins de détails
                <font-awesome-icon icon="angle-up" class="m-0 icon-color-normal" />
              </button>
            </div>
          </div>
          <div
            v-if="facture && facture.acheteur"
            class="card-style table-rapport-style table-rapport-style-client"
          >
            <v-row>
              <v-col cols="9" class="card-name">Client</v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-radio-group v-model="facture.acheteur.type" row>
                  <v-radio
                    v-for="option in options"
                    :key="'description' + option.value"
                    :label="option.text"
                    :value="option.value"
                    class="label-radios label-radio"
                    color="#704ad1"
                  ></v-radio>
                  <button
                    v-if="
                      facture &&
                        facture.acheteur &&
                        facture.acheteur.type == 'passage' &&
                        checkPermission('FLACP') &&
                        facture.acheteur.type == 'passage'
                    "
                    type="button"
                    class="ml-2 btn-add-tva-unite"
                    @click.prevent.stop="addNewPassage('show')"
                  >
                    <font-awesome-icon
                      icon="plus"
                      class="icon-plus-tva-unite"
                    />
                  </button>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row v-if="facture.acheteur.type === 'professionnel'">
              <v-col cols="6">
                <v-autocomplete
                  v-model="facture.acheteur.filiale_id"
                  :items="societeProfessionel"
                  @change="
                    facture.acheteur.filiale_id = $event;
                    acheteurSelectedPro();
                  "
                  placeholder="Séléctionnez"
                  :disabled="computedCheckTypeFactureAvoir"
                  :persistent-placeholder="true"
                  label="Nom *"
                  item-text="name"
                  item-value="id"
                  :auto-focus="false"
                  no-data-text="Aucune société trouvée"
                  outlined
                  required
                  color="#704ad1"
                  item-color="#704ad1"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  placeholder="Séléctionnez"
                  :items="getTypeSociete"
                  class="select-menu"
                  label="Type Société"
                  item-text="type"
                  item-value="type"
                  no-data-text="Aucun type trouvé"
                  :persistent-placeholder="true"
                  v-model="facture.acheteur.type_societe"
                  outlined
                  color="#704ad1"
                  :menu-props="{ bottom: true, offsetY: true }"
                  dense
                  :auto-focus="false"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-if="facture.acheteur.type === 'passage'">
              <v-col cols="12">
                <v-autocomplete
                  v-model="facture.acheteur.filiale_id"
                  :items="societePassage"
                  @change="
                    facture.acheteur.filiale_id = $event;
                    acheteurSelectedPassage();
                  "
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  label="Nom *"
                  item-text="name"
                  item-value="id"
                  :auto-focus="false"
                  no-data-text="Aucune société trouvée"
                  outlined
                  color="#704ad1"
                  item-color="#704ad1"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-if="facture.acheteur.type === 'particulier'">
              <v-col cols="6">
                <v-select
                  placeholder="Séléctionnez"
                  :items="genreList"
                  class="select-menu"
                  label="Civilité"
                  :persistent-placeholder="true"
                  v-model="facture.acheteur.civilite"
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  dense
                  :auto-focus="false"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  v-model="facture.acheteur.filiale_id"
                  :items="societeParticulier"
                  @change="acheteurSelectedParticulier"
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  label="Nom et prénom *"
                  item-text="name_part"
                  item-value="id"
                  :auto-focus="false"
                  no-data-text="Aucune société trouvée"
                  outlined
                  color="#704ad1"
                  item-color="#704ad1"
                  required
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-select
                  placeholder="Séléctionnez"
                  :items="num_tva_siren"
                  class="select-menu"
                  label="TVA ou SIRET"
                  item-text="type"
                  item-value="type"
                  :persistent-placeholder="true"
                  v-model="facture.acheteur.siren_tva"
                  outlined
                  color="#704ad1"
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-color="#704ad1"
                  dense
                  :auto-focus="false"
                >
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-if="
                    facture &&
                      facture.acheteur &&
                      facture.acheteur.siren_tva === 'Numéro TVA'
                  "
                  label="Numéro"
                  :persistent-placeholder="true"
                  v-model="facture.acheteur.tva_value"
                  color="#704ad1"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-if="
                    facture &&
                      facture.acheteur &&
                      facture.acheteur.siren_tva === 'SIREN'
                  "
                  label="Numéro"
                  :persistent-placeholder="true"
                  v-model="facture.acheteur.siren_value"
                  outlined
                  color="#704ad1"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  outlined
                  label="N° et nom de rue *"
                  v-model="facture.acheteur.adresse"
                  :persistent-placeholder="true"
                  rows="3"
                  :required="
                    facture.acheteur.type === 'particulier' ? false : true
                  "
                  color="#704ad1"
                ></v-textarea>
              </v-col>
            </v-row>

            <button
              v-if="!hideAcheteur"
              class="more_less"
              @click="showMoreAcheteur"
              type="button"
            >
              Plus de détails
              <font-awesome-icon icon="angle-down" class="mt-1 icon-color-normal" />
            </button>

            <div v-if="hideAcheteur" class="padding-top-17">
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="Code Postal *"
                    :persistent-placeholder="true"
                    v-model="facture.acheteur.code_postal"
                    :required="
                      facture.acheteur.type === 'particulier' ? false : true
                    "
                    outlined
                    color="#704ad1"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Ville *"
                    :persistent-placeholder="true"
                    v-model="facture.acheteur.ville"
                    :required="
                      facture.acheteur.type === 'particulier' ? false : true
                    "
                    outlined
                    color="#704ad1"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-select
                    placeholder="Séléctionnez"
                    :items="uniqueArrayOfCountries"
                    class="select-menu"
                    label="Pays"
                    item-text="nom"
                    item-value="nom"
                    no-data-text="Aucun pays trouvé"
                    :persistent-placeholder="true"
                    v-model="facture.acheteur.pays"
                    :menu-props="{ bottom: true, offsetY: true }"
                    outlined
                    dense
                    :auto-focus="false"
                    color="#704ad1"
                    item-color="#704ad1"
                  >
                  </v-select>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Siret *"
                    :persistent-placeholder="true"
                    v-model="facture.acheteur.siret"
                    required
                    outlined
                    color="#704ad1"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Adress Mail"
                    :persistent-placeholder="true"
                    v-model="facture.acheteur.email"
                    required
                    :rules="emailRules"
                    outlined
                    color="#704ad1"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="Téléphone"
                    :persistent-placeholder="true"
                    v-model="facture.acheteur.telephone"
                    outlined
                    color="#704ad1"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Téléphone portable"
                    :persistent-placeholder="true"
                    v-model="facture.acheteur.telephone_portable"
                    required
                    outlined
                    color="#704ad1"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-textarea
                    outlined
                    label="Description additionnelle"
                    v-model="facture.acheteur.description"
                    :persistent-placeholder="true"
                    rows="3"
                    color="#704ad1"
                    required
                  ></v-textarea>
                </v-col>
              </v-row>
            </div>
            <button
              v-if="hideAcheteur"
              class="more_less"
              @click="showMoreAcheteur"
              type="button"
            >
              moins de détails
              <font-awesome-icon icon="angle-up" class="m-0 icon-color-normal" />
            </button>
          </div>
          <v-row v-if="facture">
            <v-col cols="3" class="card-name product-name">Objet</v-col>
            <v-col cols="9"></v-col>
          </v-row>
          <v-row v-if="facture" class="card-style">
            <v-col cols="12">
              <EditorSimple
                classComponent="objet"
                v-model="facture.objet"
                @changeText="changeTextObjet"
              ></EditorSimple>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3" class="card-name product-name">Désignations</v-col>
            <v-col cols="5"></v-col>
            <v-col cols="2">
              <button
                type="button"
                class="btn-product btn-depot"
                v-if="showDep"
                @click="hideDepot"
              >
                <font-awesome-icon icon="minus" class="icon-plus" />

                Dépôt
              </button>
              <button
                type="button"
                class="btn-product btn-depot "
                v-if="!showDep"
                @click="showDepot"
              >
                <font-awesome-icon icon="plus" class="icon-plus" />

                Dépôt
              </button>
            </v-col>
            <v-col cols="2">
              <button
                type="button"
                class="btn-product"
                v-if="showReduc"
                @click="hideReduction"
              >
                <font-awesome-icon icon="minus" class="icon-plus" />

                Réduction
              </button>
              <button
                type="button"
                class="btn-product"
                v-if="!showReduc"
                @click="showReduction"
              >
                <font-awesome-icon icon="plus" class="icon-plus" />

                Réduction
              </button>
            </v-col>
          </v-row>

          <div
            v-for="(produit, index) in facture.produits"
            :key="'product' + index"
            class="card-style block-prod"
          >
            <v-row
              v-if="produit.type === 'produit'"
              class="justify-space-between"
            >
              <v-col :cols="showReduc && showDep ? '1' : '2'">
                <v-text-field
                  label="Nom *"
                  :persistent-placeholder="true"
                  v-model="produit.nom"
                  @input="filterResultsProduit(produit.nom, index, 'avant')"
                  v-click-outside="onClickOutsideProduct"
                  required
                  outlined
                  color="#704ad1"
                ></v-text-field>
                <div>
                  <ul
                    :key="'produitUl' + index"
                    v-if="resultsProduits && produit.isOpenProduitList"
                    class="
                                autocomplete-results
                                list-unstyled style_liste_products
                              "
                  >
                    <li
                      v-for="result in resultsProduits"
                      :key="result"
                      @click="setResultsProduct(result, produit)"
                    >
                      {{ result }}
                    </li>
                  </ul>
                </div>
              </v-col>
              <v-col cols="1">
                <v-text-field
                  label="Réf *"
                  :persistent-placeholder="true"
                  v-model="produit.reference"
                  outlined
                  color="#704ad1"
                ></v-text-field>
              </v-col>
              <v-col cols="1" v-if="showDep">
                <v-text-field
                  label="Dépôt *"
                  :persistent-placeholder="true"
                  v-model="produit.depot"
                  outlined
                  color="#704ad1"
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-text-field
                  label="Qté *"
                  :persistent-placeholder="true"
                  v-model="produit.qte"
                  type="number"
                  @input="calculTotalHtTtc(produit)"
                  step="any"
                  outlined
                  :min="0"
                  color="#704ad1"
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-select
                  :items="getAllUnites"
                  class="select-menu"
                  label="Unité"
                  :persistent-placeholder="true"
                  v-model="produit.unite"
                  item-value="valeur"
                  item-text="valeur"
                  @change="changeUnite(produit)"
                  no-data-text=""
                  required
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  dense
                  :auto-focus="false"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
              <v-col cols="1" class="col-plus">
                <v-btn
                  class="mx-2 button-add"
                  small
                  @click.stop.prevent="ModalAddUnite('show')"
                >
                  <v-icon dark>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col cols="1">
                <v-select
                  v-model="produit.prestation_marchandise"
                  :items="typeProduct"
                  item-text="text"
                  item-value="value"
                  dense
                  outlined
                  hide-details
                  class="mb-4"
                  label="Type"
                  item-color="#704ad1"
                  color="#704ad1"
                  :persistent-placeholder="true"
                  placeholder="Sélectionner"
                  no-data-text="Aucun element trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-select>
              </v-col>
              <v-col cols="1">
                <v-text-field
                  label="PU HT *"
                  :persistent-placeholder="true"
                  v-model="produit.pu_ht"
                  type="number"
                  required
                  step="any"
                  @input="calculTotalHtTtc(produit)"
                  outlined
                  :min="0"
                  color="#704ad1"
                ></v-text-field>
              </v-col>
              <v-col
                cols="1"
                v-if="
                  showReduc && facture.comment_calculer_facture === 'montant'
                "
              >
                <v-text-field
                  label="Red. Mt *"
                  :persistent-placeholder="true"
                  v-model="produit.reduction"
                  type="number"
                  :max="
                    facture.comment_calculer_facture === 'pourcentage'
                      ? 100
                      : produit.pu_ht
                  "
                  :min="0"
                  required
                  step="any"
                  @input="change_reduction(produit)"
                  outlined
                  color="#704ad1"
                ></v-text-field>
              </v-col>
              <v-col
                cols="1"
                v-if="
                  showReduc &&
                    facture.comment_calculer_facture === 'pourcentage'
                "
              >
                <v-text-field
                  label="Red. % *"
                  :persistent-placeholder="true"
                  v-model="produit.reduction"
                  :max="
                    facture.comment_calculer_facture === 'pourcentage'
                      ? 100
                      : produit.pu_ht
                  "
                  :min="0"
                  type="number"
                  step="any"
                  required
                  @input="change_reduction(produit)"
                  outlined
                  color="#704ad1"
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-text-field
                  @input="onChangeTotalHt(produit)"
                  label="NET HT"
                  :persistent-placeholder="true"
                  type="number"
                  step="any"
                  required
                  v-model="produit.total_ht"
                  outlined
                  :min="0"
                  color="#704ad1"
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <div class="d-flex">
                  <v-select
                    @change="calculTotalHtTtc(produit)"
                    :items="computedgetAllTvas"
                    class="select-menu"
                    label="TVA *"
                    :persistent-placeholder="true"
                    v-model="produit.tva"
                    item-value="valeur"
                    item-text="valeur"
                    no-data-text=""
                    required
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                    dense
                    :auto-focus="false"
                    color="#704ad1"
                    item-color="#704ad1"
                  >
                  </v-select>
                  <tva
                    :filiale_id="facture.vendeur.idOrigine"
                    @addTva="addTvaAction"
                    v-if="facture.vendeur.idOrigine"
                  ></tva>
                </div>
              </v-col>
              <v-col cols="1">
                <v-text-field
                  label="Total TTC *"
                  :persistent-placeholder="true"
                  v-model="produit.total_ttc"
                  disabled
                  required
                  outlined
                  color="#704ad1"
                ></v-text-field>
              </v-col>
              <v-col cols="1" class="col-plus">
                <v-btn
                  class="mx-2 button-add"
                  small
                  color="red"
                  @click="deleteProduct(index)"
                >
                  <v-icon dark>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="produit.type === 'produit'" class="row-radios">
              <v-col cols="12">
                <v-radio-group v-model="produit.isSelectInput" column>
                  <v-radio
                    label="Ajouter le produit à votre catalogue"
                    v-model="produit.isSelectInput"
                    color="#704ad1"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row v-if="produit.type === 'produit'">
              <v-col cols="12">
                <EditorSimple
                  classComponent="produit-description"
                  v-model="produit.description"
                  @changeText="changeText($event, index)"
                ></EditorSimple>
              </v-col>
            </v-row>
            <v-row v-if="produit.type === 'sous_total'">
              <v-col cols="9">
                <v-text-field
                  label="Sous total Net Ht"
                  :persistent-placeholder="true"
                  :value="computedSumNetHtBeforSt(index)"
                  disabled
                  outlined
                  color="#704ad1"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-btn
                  small
                  @click="deleteProduct(index)"
                  color="red"
                  class="mx-2 button-add"
                >
                  X
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="produit.type === 'saut_page'">
              <v-col cols="9">
                <v-text-field
                  label="Saut de page"
                  :persistent-placeholder="true"
                  v-model="sautPage"
                  disabled
                  outlined
                  color="#704ad1"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-btn
                  small
                  @click="deleteProduct(index)"
                  color="red"
                  class="mx-2 button-add"
                >
                  X
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="produit.type === 'ligne_text'">
              <v-col cols="9">
                <EditorSimple
                  classComponent="ligne-text"
                  v-model="produit.contenu"
                  @changeText="changeLigneText($event, index)"
                ></EditorSimple>
              </v-col>
              <v-col cols="3">
                <v-btn
                  small
                  @click="deleteProduct(produit.index, produit.type, produit)"
                  color="red"
                  class="mx-2 button-add"
                >
                  X
                </v-btn>
              </v-col>
            </v-row>
          </div>

          <v-row class="row-total">
            <v-col cols="1" class="col-new-product">
              <button @click="addNewProduct" class="btn-product" type="button">
                <font-awesome-icon icon="plus" class="icon-plus" />

                Produit
              </button>
            </v-col>
            <v-col cols="2" class="col-new-line">
              <button @click="addNewTextLine" class="btn-product" type="button">
                <font-awesome-icon icon="plus" class="icon-plus" />
                Ligne de texte
              </button>
            </v-col>
            <v-col cols="2" class="col-new-saut">
              <button
                @click="addNewSautPage"
                class="btn-product  "
                type="button"
              >
                <font-awesome-icon icon="plus" class="icon-plus" />
                Saut de page
              </button>
            </v-col>
            <v-col cols="2" class="col-new-total">
              <button
                @click="addNewSousTotal"
                class="btn-product"
                type="button"
              >
                <font-awesome-icon icon="plus" class="icon-plus" />
                Sous Total
              </button>
            </v-col>
          </v-row>
          <div class="card-style card-total" v-if="facture && facture.devise">
            <v-row>
              <v-col cols="2">
                <v-text-field
                  label="Total HT"
                  :persistent-placeholder="true"
                  :value="
                    formateValueFacture(
                      (Math.round(totalHt * 100) / 100).toFixed(2)
                    ) || 0 + '' + facture.devise + ''
                  "
                  disabled
                  required
                  outlined
                  color="#704ad1"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-select
                  :items="currencyList"
                  class="select-menu"
                  label="DEVISE *"
                  :persistent-placeholder="true"
                  v-model="facture.devise"
                  no-data-text=""
                  outlined
                  dense
                  :auto-focus="false"
                  color="#704ad1"
                  item-color="#704ad1"
                  :menu-props="{ bottom: true, offsetY: true }"
                >
                </v-select>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  label="Total Remise"
                  :persistent-placeholder="true"
                  :value="
                    formateValueFacture(
                      (Math.round(totalReduction * 100) / 100).toFixed(2)
                    ) || 0 + '' + facture.devise + ''
                  "
                  disabled
                  outlined
                  color="#704ad1"
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-text-field
                  label="NET HT"
                  :persistent-placeholder="true"
                  :value="
                    formateValueFacture(
                      (Math.round(NetHt * 100) / 100).toFixed(2)
                    ) || 0 + '' + facture.devise + ''
                  "
                  disabled
                  outlined
                  color="#704ad1"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  label="Total TVA"
                  :persistent-placeholder="true"
                  :value="
                    formateValueFacture(total_tva.toFixed(2)) ||
                      0 + '' + facture.devise + ''
                  "
                  disabled
                  color="#704ad1"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  label="Total TTC "
                  :persistent-placeholder="true"
                  :value="
                    formateValueFacture(
                      (Math.round(totalTtc * 100) / 100).toFixed(2)
                    ) || 0 + ' ' + facture.devise
                  "
                  disabled
                  outlined
                  color="#704ad1"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>

          <div class="card-style">
            <v-row v-if="facture && computedCheckReglement">
              <v-col cols="3">
                <v-select
                  :items="computedTypeReglement"
                  class="select-menu"
                  label="Mode de règlement *"
                  :persistent-placeholder="true"
                  v-model="facture.mode_reglement"
                  no-data-text=""
                  outlined
                  dense
                  required
                  :auto-focus="false"
                  color="#704ad1"
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  :items="computedConditionPaiement"
                  class="select-menu"
                  label="Conditions de paiement *"
                  :persistent-placeholder="true"
                  v-model="facture.date_limite_reglement"
                  no-data-text=""
                  outlined
                  dense
                  required
                  :auto-focus="false"
                  color="#704ad1"
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
              <v-col cols="2">
                <v-select
                  :items="
                    facture.type === 'Facture proforma' ||
                    facture.type === 'Devis'
                      ? etatListProforma
                      : etatList
                  "
                  class="select-menu"
                  label="Etat *"
                  :persistent-placeholder="true"
                  v-model="facture.etat"
                  :disabled="true"
                  no-data-text=""
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
              <v-col
                cols="2"
                v-if="
                  facture.type !== 'Facture proforma' &&
                    facture.type !== 'Devis'
                "
              >
                <v-text-field
                  label="Acompte Payé *"
                  :persistent-placeholder="true"
                  :max="totalTtc"
                  v-model="facture.montant_payer"
                  disabled
                  required
                  outlined
                  color="#704ad1"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="showReduc && facture && computedCheckReglement">
              <v-col cols="3">
                <v-select
                  :items="calculeReduction"
                  class="select-menu"
                  label="Comment calculer la réduction *"
                  @change="onChangeCommentCalculer"
                  :persistent-placeholder="true"
                  v-model="facture.comment_calculer_facture"
                  no-data-text=""
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
              <v-col
                cols="3"
                v-if="facture.comment_calculer_facture !== 'montant'"
              >
                <v-text-field
                  label="Ajouter une réduction globale (%)"
                  :persistent-placeholder="true"
                  v-model="facture.ajout_reduction_global"
                  @input="
                    onChangeGlobalReduction(facture.ajout_reduction_global)
                  "
                  required
                  outlined
                  color="#704ad1"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="facture && computedCheckReglement">
              <v-col cols="9">
                <v-select
                  :items="computedActiveRib"
                  class="select-menu"
                  label="RIB *"
                  :persistent-placeholder="true"
                  v-model="rib"
                  @change="onChangeRibSelect"
                  no-data-text=""
                  item-text="rib"
                  item-value="id"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row v-if="facture && computedCheckReglement">
              <v-col cols="3">
                <v-text-field
                  label="IBAN *"
                  :persistent-placeholder="true"
                  v-model="facture.iban"
                  disabled
                  required
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  label="BIC *"
                  :persistent-placeholder="true"
                  v-model="facture.swift"
                  disabled
                  required
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="3">
                <v-text-field
                  label="BANQUE *"
                  :persistent-placeholder="true"
                  :max="totalTtc"
                  v-model="facture.bank"
                  disabled
                  required
                  outlined
                  color="#704ad1"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-select
                  :items="currencyList"
                  class="select-menu"
                  label="DEVISE *"
                  :persistent-placeholder="true"
                  v-model="facture.devise"
                  no-data-text=""
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col cols="12"> -->
            <v-radio-group v-model="facture.displayDiscription" row>
              <v-radio
                v-for="option in displayFactureOptions"
                :key="'description' + option.value"
                :label="option.text"
                :value="option.value"
                class="label-radios label-radio"
                color="#704ad1"
              ></v-radio>
            </v-radio-group>
            <!-- </v-col>
                 
            </v-row> -->
            <v-row>
              <v-col cols="12">
                <EditorSimple
                  classComponent="description-facture"
                  v-model="facture.description"
                  @changeText="changeTextDescription"
                  :disabledComponent="false"
                ></EditorSimple>
              </v-col>
            </v-row>
          </div>
          <div class="card-style" v-if="facture && templateSociete.length != 0">
            <v-row>
              <v-col cols="3">
                <v-select
                  :items="templateSociete"
                  class="select-menu"
                  label="Template de téléchargement"
                  :persistent-placeholder="true"
                  v-model="facture.template_id"
                  no-data-text=""
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  :auto-focus="false"
                  color="#704ad1"
                  item-color="#704ad1"
                  :menu-props="{ bottom: true, offsetY: true }"
                >
                </v-select
              ></v-col>
            </v-row>
          </div>

          <div class="card-style">
            <v-row>
              <v-col cols="12">
                <v-file-input
                  v-model="files"
                  counter
                  label="Fichiers"
                  multiple
                  placeholder="Aucun fichier selectionné"
                  :persistent-placeholder="true"
                  prepend-icon="mdi-paperclip"
                  outlined
                  :show-size="1000"
                  color="#704ad1"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip label small>
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <v-row v-for="(file, index) in files" :key="'file' + index">
              <v-col cols="4"> {{ file.name }}</v-col>
              <v-col cols="7">
                <v-textarea
                  outlined
                  label="Description"
                  v-model="facture.vendeur.adresse"
                  :persistent-placeholder="true"
                  rows="3"
                  required
                  color="#704ad1"
                ></v-textarea
              ></v-col>
              <v-col cols="1">
                <v-btn
                  class="mx-2 button-add"
                  small
                  color="red"
                  @click="deleteUploadFile(index)"
                >
                  <v-icon dark>
                    mdi-close
                  </v-icon>
                </v-btn></v-col
              >
            </v-row>
          </div>
          <div v-if="errorAddFactureLibre" class="message-error-modal">
            <ul v-if="Array.isArray(errorAddFactureLibre)" class="mb-0">
              <li v-for="(e, index) in errorAddFactureLibre" :key="index">
                {{ e }}
              </li>
            </ul>
            <div class="message-error-modal" v-else>
              {{ errorAddFactureLibre }}
            </div>
            <div v-if="errorValidation">{{ this.errorValidation }}</div>
          </div>
          <v-row>
            <v-col cols="2">
              <v-btn
                type="submit"
                outlined
                :disabled="disabledSaveButton"
                :loading="loading"
                color="#704ad1"
              >
                Sauvegarde
              </v-btn>
            </v-col>
            <v-col cols="3">
              <v-btn
                type="button"
                @click="displayBill"
                color="#704ad1"
                outlined
                :loading="loadingDisplay"
                :disabled="loadingDisplay"
              >
                Aperçu provisoire
              </v-btn>
            </v-col>
            <v-col cols="4"></v-col>
            <v-col cols="3" class="d-flex justify-end">
              <!-- <v-btn
                type="button"
                @click="$router.push('/facture-libre')"
                color="#704ad1"
                outlined
              >
                <font-awesome-icon icon="arrow-left" class="mr-2" /> Retour à
                l'interface facture libre
              </v-btn> -->
            </v-col>
          </v-row>
        </div>
      </form>
      <v-btn
        type="button"
        @click="$router.push('/facture-libre')"
        outlined
        title=" Retour à l'interface facture libre"
        class="return-principel-item"
      >
        <font-awesome-icon :icon="['fad','turn-down-left']" class="mr-2" />
        <span class="title"> Retour à l'interface facture libre</span>
      </v-btn>
      <v-dialog
        v-model="modalAddCategorie"
        persistent
        max-width="600px"
        :scrollable="true"
        :hide-overlay="true"
        no-click-animation
        content-class="custom-vuetify-dialog-add"
      >
        <v-card>
          <v-card-title class="text-h6 title-modal">
            <v-label class="label-header">Ajouter catégorie </v-label>
            <v-btn
              class="btn-close-header"
              icon
              @click="ModalAddCategorie('hide')"
              title="Fermer"
              color="#704ad1"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider class="line-hr"></v-divider>
          <v-card-text class="body-card">
            <form class="form-add">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Nom"
                    v-model="nom_categorie"
                    :persistent-placeholder="true"
                    required
                    outlined
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </form>
          </v-card-text>
          <div v-if="errorCategorie" class="message-error-modal">
            <ul v-if="Array.isArray(errorCategorie)" class="mb-0">
              <li v-for="(e, index) in errorCategorie" :key="index">
                {{ e }}
              </li>
            </ul>
            <div class="message-error-modal" v-else>{{ errorCategorie }}</div>
          </div>
          <v-divider class="line-hr-footer"></v-divider>
          <v-card-actions class="actions-msg-btn-modal">
            <v-spacer></v-spacer>
            <v-btn
              color="#704ad1"
              text
              @click="addCat"
              :loading="loadingCategorie"
              :disabled="loadingCategorie"
              :class="{ loader: loadingCategorie }"
            >
              Ajouter
            </v-btn>
            <v-btn text @click="ModalAddCategorie('hide')">
              Annuler
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="modalAddUnite"
        persistent
        max-width="600px"
        :scrollable="true"
        :hide-overlay="true"
        no-click-animation
        content-class="custom-vuetify-dialog-add"
      >
        <v-card>
          <v-card-title class="text-h6 title-modal">
            <v-label class="label-header">Ajouter Unité </v-label>
            <v-btn
              class="btn-close-header"
              icon
              @click="ModalAddUnite('hide')"
              title="Fermer"
              color="#704ad1"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider class="line-hr"></v-divider>
          <v-card-text class="body-card">
            <v-form class="form-add" ref="addUnite">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Unité"
                    v-model="valeur"
                    :persistent-placeholder="true"
                    required
                    outlined
                    :rules="[v => !!v || 'Unité est obligatoire']"
                    class="msg-error"
                    validate-on-blur
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <div v-if="errorTvaUnite" class="message-error-modal">
            <ul v-if="Array.isArray(errorTvaUnite)" class="mb-0">
              <li v-for="(e, index) in errorTvaUnite" :key="index">
                {{ e }}
              </li>
            </ul>
            <div class="message-error-modal" v-else>{{ errorTvaUnite }}</div>
          </div>
          <v-divider class="line-hr-footer"></v-divider>
          <v-card-actions class="actions-msg-btn-modal">
            <v-spacer></v-spacer>
            <v-btn
              color="#704ad1"
              text
              @click="addUnite('unité')"
              :loading="loadingTvaUnite"
              :disabled="loadingTvaUnite"
              :class="{ loader: loadingTvaUnite }"
            >
              Ajouter
            </v-btn>
            <v-btn text @click="ModalAddUnite('hide')">
              Annuler
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="showFacture"
        persistent
        max-width="800"
        hide-overlay
        content-class="custom-vuetify-dialog-show"
      >
        <v-card>
          <v-card-title class="text-h6 title-modal">
            <v-label class="label-header">Afficher Facture </v-label>
            <v-btn
              class="btn-close-header"
              icon
              @click="ModalShowFacture('hide')"
              title="Fermer"
              color="#704ad1"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider class="line-hr"></v-divider>
          <v-card-text class="body-card">
            <iframe
              height="750"
              width="750"
              :src="pdfSource + '#toolbar=0'"
              scrolling="no"
            ></iframe>
          </v-card-text>
          <v-divider class="line-hr-footer"></v-divider>
          <v-card-actions class="actions-msg-btn-modal">
            <!-- <div v-if="error" class="message-error-modal">
              <ul v-if="Array.isArray(error)" class="mb-0">
                <li v-for="(e, index) in error" :key="index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ error }}</div>
            </div> -->
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ADD MODAL CLIENT  -->
      <v-dialog
        v-model="addClientModal"
        max-width="820px"
        hide-overlay
        persistent
        scrollable
        content-class="custom-vuetify-dialog-gestion"
      >
        <v-card>
          <v-card-title class="text-h6 title-modal">
            <v-label class="label-header">Ajouter un client passager</v-label>
            <v-btn
              class="btn-close-header"
              icon
              @click.prevent="hideModal('addClientModal')"
              title="Fermer"
              color="#704ad1"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider class="line-hr-header"></v-divider>

          <v-card-text class="body-card center-text  mt-2">
            <v-form class="mt-2" ref="formClientPassage" lazy-validation>
              <v-row class="mt-2">
                <v-col>
                  <v-text-field
                    label="Nom de client *"
                    :persistent-placeholder="true"
                    v-model="passageToAdd.name"
                    dense
                    outlined
                    required
                    :auto-focus="false"
                    class="msg-error"
                    validate-on-blur
                    :rules="[v => !!v || 'Nom de client est obligatoire']"
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    :items="num_tva_siren"
                    v-model="passageToAdd.siren_tva"
                    label=""
                    dense
                    outlined
                    no-data-text="Aucune option trouvé"
                    :menu-props="{ bottom: true, offsetY: true }"
                    color="#704ad1"
                    item-color="#704ad1"
                  ></v-select>
                </v-col>
                <v-col
                  v-if="passageToAdd && passageToAdd.siren_tva === 'Numéro TVA'"
                >
                  <v-text-field
                    label=""
                    :persistent-placeholder="true"
                    v-model="passageToAdd.tva_value"
                    dense
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  v-if="passageToAdd && passageToAdd.siren_tva === 'SIREN'"
                >
                  <v-text-field
                    label=""
                    :persistent-placeholder="true"
                    v-model="passageToAdd.siren_value"
                    dense
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea
                    outlined
                    label="N° et nom de rue *"
                    v-model="passageToAdd.adresse"
                    :persistent-placeholder="true"
                    required
                    rows="2"
                    :rules="[v => !!v || 'N° et nom de rue est obligatoire']"
                    class="msg-error"
                    validate-on-blur
                    color="#704ad1"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Code postal *"
                    :persistent-placeholder="true"
                    v-model="passageToAdd.code_postal"
                    required
                    :rules="[v => !!v || 'Code postal est obligatoire']"
                    class="msg-error"
                    validate-on-blur
                    dense
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Ville *"
                    :persistent-placeholder="true"
                    v-model="passageToAdd.ville"
                    required
                    :rules="[v => !!v || 'Ville est obligatoire']"
                    class="msg-error"
                    validate-on-blur
                    dense
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field
                ></v-col>
                <v-col>
                  <v-select
                    :items="computedListPays"
                    v-model="passageToAdd.pays"
                    label="Pays *"
                    dense
                    outlined
                    required
                    :persistent-placeholder="true"
                    :rules="[v => !!v || 'Pays est obligatoire']"
                    class="msg-error"
                    validate-on-blur
                    no-data-text="Aucune Pays trouvée"
                    :menu-props="{ bottom: true, offsetY: true }"
                    item-text="nom"
                    item-value="nom"
                    color="#704ad1"
                    item-color="#704ad1"
                  ></v-select
                ></v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea
                    outlined
                    label="Description additionnelle"
                    rows="2"
                    v-model="passageToAdd.description"
                    :persistent-placeholder="true"
                    color="#704ad1"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Siret"
                    :persistent-placeholder="true"
                    v-model="passageToAdd.siret"
                    dense
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Adresse Email *"
                    :persistent-placeholder="true"
                    v-model="passageToAdd.email"
                    required
                    :rules="[v => !!v || 'Adresse Email est obligatoire']"
                    class="msg-error"
                    validate-on-blur
                    dense
                    outlined
                    type="email"
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Téléphone"
                    :persistent-placeholder="true"
                    v-model="passageToAdd.telephone"
                    dense
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Téléphone portable *"
                    :persistent-placeholder="true"
                    v-model="passageToAdd.telephone_portable"
                    required
                    :rules="[v => !!v || 'Téléphone portable est obligatoire']"
                    class="msg-error"
                    validate-on-blur
                    dense
                    outlined
                    color="#704ad1"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <div v-if="error" class="message-error-modal">
            <ul v-if="Array.isArray(error)">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ error }}</div>
          </div>
          <v-divider class="line-hr-footer"></v-divider>
          <v-card-actions class="actions-msg-btn-modal">
            <v-spacer></v-spacer>
            <v-btn
              color="#704ad1"
              outlined
              :loading="loading"
              :class="{ loader: loading }"
              @click.prevent.stop="handleSubmitClient"
              >Valider
            </v-btn>
            <v-btn outlined @click.prevent="hideModal('addClientModal')">
              Annuler
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!-- ADD MODAL CLIENT  -->
    <v-dialog
      v-model="modalClientPassage"
      max-width="820px"
      hide-overlay
      persistent
      scrollable
      content-class="custom-vuetify-dialog-gestion"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Ajouter client passager</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="addNewPassage('hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form class="mt-2" ref="form" lazy-validation>
            <v-row class="mt-2">
              <v-col>
                <v-text-field
                  label="Nom de client *"
                  :persistent-placeholder="true"
                  v-model="passageToAdd.name"
                  dense
                  outlined
                  required
                  :auto-focus="false"
                  class="msg-error"
                  validate-on-blur
                  :rules="[v => !!v || 'Nom de client est obligatoire']"
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  :items="num_tva_siren"
                  v-model="passageToAdd.siren_tva"
                  label=""
                  dense
                  outlined
                  no-data-text="Aucune option trouvé"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                ></v-select>
              </v-col>
              <v-col
                v-if="passageToAdd && passageToAdd.siren_tva === 'Numéro TVA'"
              >
                <v-text-field
                  label=""
                  :persistent-placeholder="true"
                  v-model="passageToAdd.tva_value"
                  dense
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col v-if="passageToAdd && passageToAdd.siren_tva === 'SIREN'">
                <v-text-field
                  label=""
                  :persistent-placeholder="true"
                  v-model="passageToAdd.siren_value"
                  dense
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  outlined
                  label="N° et nom de rue *"
                  v-model="passageToAdd.adresse"
                  :persistent-placeholder="true"
                  required
                  rows="2"
                  :rules="[v => !!v || 'N° et nom de rue est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  color="#704ad1"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Code postal *"
                  :persistent-placeholder="true"
                  v-model="passageToAdd.code_postal"
                  required
                  :rules="[v => !!v || 'Code postal est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  dense
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Ville *"
                  :persistent-placeholder="true"
                  v-model="passageToAdd.ville"
                  required
                  :rules="[v => !!v || 'Ville est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  dense
                  outlined
                  color="#704ad1"
                >
                </v-text-field
              ></v-col>
              <v-col>
                <v-select
                  :items="computedListPays"
                  v-model="passageToAdd.pays"
                  label="Pays *"
                  dense
                  outlined
                  required
                  :persistent-placeholder="true"
                  :rules="[v => !!v || 'Pays est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  no-data-text="Aucune Pays trouvée"
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-text="nom"
                  item-value="nom"
                  color="#704ad1"
                  item-color="#704ad1"
                ></v-select
              ></v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  outlined
                  label="Description additionnelle"
                  rows="2"
                  v-model="passageToAdd.description"
                  :persistent-placeholder="true"
                  color="#704ad1"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Siret"
                  :persistent-placeholder="true"
                  v-model="passageToAdd.siret"
                  dense
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Adresse Email *"
                  :persistent-placeholder="true"
                  v-model="passageToAdd.email"
                  required
                  :rules="emailRules"
                  class="msg-error"
                  validate-on-blur
                  dense
                  outlined
                  type="email"
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Téléphone"
                  :persistent-placeholder="true"
                  v-model="passageToAdd.telephone"
                  dense
                  outlined
                  type="number"
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Téléphone portable *"
                  :persistent-placeholder="true"
                  v-model="passageToAdd.telephone_portable"
                  type="number"
                  :rules="[v => !!v || 'Téléphone portable est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  dense
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <div v-if="error" class="message-error-modal">
          <ul v-if="Array.isArray(error)">
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <div v-else>{{ error }}</div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            :loading="loading"
            :class="{ loader: loading }"
            @click.prevent.stop="handleSubmitPassage"
            >Valider
          </v-btn>
          <v-btn outlined @click.prevent="addNewPassage('hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import ClickOutside from 'vue-click-outside';
export default {
  data() {
    return {
      typeProduct: [
        { value: 'prestation', text: 'Prestation' },
        { value: 'marchandise', text: 'Marchandise' }
      ],
      addClientModal: false,
      getAllTvas: [],
      loaderComponent: true,
      loadingDisplay: false,
      modalAddCategorie: false,
      showFacture: false,
      modalAddUnite: false,
      emailRules: [
        v => !!v || 'Email is required',
        v => /.+@.+\..+/.test(v) || 'Email must be valid'
      ],
      minDateForAvoir: null,
      checked: false,
      testRetourErrors: false,
      disabledSaveButton: false,
      loadingClientPassage: false,
      modalClientPassage: false,
      passageToAdd: {
        name: null,
        siren_tva: 'Numéro TVA',
        siren_value: null,
        tva_value: null,
        adresse: null,
        code_postal: null,
        pays: null,
        email: null,
        siret: null,
        telephone: null,
        telephone_portable: null,
        description: null,
        ville: null,
        type: 'passage'
      },
      options: [
        { text: 'Professionnel', value: 'professionnel' },
        { text: 'Particulier', value: 'particulier' },
        { text: 'Passage', value: 'passage' }
      ],
      optionsAvoir: [
        { text: 'Retour avoir', value: 'retour_avoir' },
        { text: 'Avoir financier', value: 'avoir_financier' },
        { text: 'Ristourne', value: 'ristourne' }
      ],
      displayFactureOptions: [
        { text: 'Afficher description', value: true },
        { text: 'Cacher description', value: false }
      ],
      typeFacture: ['Facture', "Facture d'avoir", 'Facture proforma', 'Devis'],
      loadingCategorie: null,
      errorCategorie: null,
      errorTvaUnite: null,
      errorValidation: null,
      idAvoir: null,
      resultsProduits: [],
      resultsListAvoir: [],
      pdfSource: null,
      sousTotal: 'Sous totale',
      sautPage: 'Saut de page',
      disabledNumFactureAvoir: false,
      loading: false,
      showReduc: false,
      showDep: false,
      hideVendeur: false,
      hideAcheteur: false,
      nom_categorie: null,
      valeur: null,
      rib: null,
      loadingTvaUnite: false,
      genreList: ['M.', 'Mme', 'Mmes', 'Mrs', 'M & Mme'],
      num_tva_siren: ['Numéro TVA', 'SIREN'],
      currencyList: ['EUR', 'USD'],
      etatList: ['Payé', 'Payé en partie', 'A payer'],
      etatListProforma: ['Créé'],
      calculeReduction: ['pourcentage', 'montant'],
      type: null,
      error: null,
      maxDate: moment(Date.now()).format('YYYY-MM-DD'),
      societeProfessionel: [],
      societeParticulier: [],
      societePassage: [],
      productListName: [],
      files: [],
      uniqueArrayOfCountries: [],
      facture: {
        template_id: null,
        numero_document_corrige: null,
        objet_color: '#c3c7e2',
        text_color: '#000000',
        motif_avoir: 'retour_avoir',
        devise: 'EUR',
        num: null,
        date_creation: moment(Date.now()).format('YYYY-MM-DD'),
        lieu_creation: null,
        categorie_id: null,
        objet: null,
        type: 'Facture',
        mode_reglement: null,
        date_limite_reglement: null,
        date_exact_limite_reglement: null,
        etat: 'A payer',
        description: null,
        montant_payer: 0,
        comment_calculer_facture: 'pourcentage',
        ajout_reduction_global: 0,
        swift: null,
        iban: null,
        objet: null,
        check: false,
        date_paiement: null,
        famille: 'libre',
        vendeur: {
          nom_compagnie: null,
          code_postal: null,
          siren_tva: 'Numéro TVA',
          siren_value: null,
          tva_value: null,
          adresse: null,
          code_postal: null,
          ville: null,
          siret: null,
          type_societe: null,
          pays: null,
          iban: null,
          banque: null,
          swift: null,
          email: null,
          site_internet: null,
          fax: null,
          telephone: null,
          description: null,
          filiale_id: null
        },
        acheteur: {
          type: 'professionnel',
          civilite: 'M.',
          prenom: null,
          type_societe: null,
          nom_famille: null,
          nom_compagnie: null,
          siren_tva: 'Numéro TVA',
          siren_value: null,
          tva_value: null,
          adresse: null,
          code_postal: null,
          ville: null,
          banque: null,
          swift: null,
          pays: null,
          email: null,
          siret: null,
          iban: null,
          telephone: null,
          telephone_portable: null,
          description: null,
          filiale_id: null
        },
        produits: [
          {
            contenu: '',
            nom: null,
            reference: null,
            depot: null,
            reduction: 0,
            qte: 1,
            unite: null,
            tva: '20',
            pu_ht: 0,
            total_ttc: 0,
            total_ht: 0,
            description: null,
            isSelectInputTva: false,
            isSelectInput: true,
            index: 0,
            type: 'produit',
            isOpenProduitList: false,
            contenu_apres: '',
            nom_apres: null,
            reference_apres: null,
            depot_apres: null,
            reduction_apres: 0,
            qte_apres: 1,
            unite_apres: ' ',
            tva_apres: 0,
            pu_ht_apres: 0,
            total_ttc_apres: 0,
            total_ht_apres: 0,
            isSelectInputTva_apres: false,
            isSelectInput_apres: true,
            index_apres: 0,
            isOpenProduitList_apres: false,
            qte_sous_total_avoir: 0,
            pu_ht_sous_total_avoir: 0,
            total_ht_sous_total_avoir: 0,
            total_ttc_sous_total_avoir: 0,
            max_pu_ht: 0,
            adresse: null,
            prestation_marchandise: 'prestation'
          }
        ],
        displayDiscription: true
      },
      focusInput: null
    };
  },
  components: {
    EditorSimple: () => import('@/views/component/EditorSimple.vue'),
    loader: () => import('../Loader.vue'),
    tva: () => import('@/views/component/tva/tva.vue')
  },
  directives: {
    ClickOutside
  },
  computed: {
    ...mapGetters([
      'checkPermission',
      'errorAddFactureLibre',
      'addFactureLibre',
      'templateSociete',
      'getSettingFilialesTh',
      'getPaysList',
      'getProduits',
      'getAllCategories',
      'getFactureLibreToUpdate',
      'getFiliaeOfResponsable',
      // 'getAllTvas',
      'getAllUnites',
      'getRibOfFiliale',
      'getTypeSociete',
      'TypeReglement',
      'ConditionPaiement'
    ]),
    computedActiveRib() {
      return this.getRibOfFiliale.filter(item => item.blocked == false);
    },

    computedProducts() {
      this.getProduits?.map(item => this.productListName?.push(item.nom));
      return this.productListName;
    },
    computedListPays() {
      let countryNames = [];
      this.getPaysList?.map(element => countryNames.push(element.nom));
      return [...new Set(countryNames)];
    },
    computedgetAllTvas() {
      return this.getAllTvas.filter(i => i.is_active == 1);
    },
    computedCheckTypeFactureAvoir() {
      return (
        this.facture.type === "Facture d'avoir" &&
        this.facture.motif_avoir !== 'ristourne'
      );
    },
    computedCheckTypeFactureNotAvoir() {
      const isAvoirFacture = this.facture.type === "Facture d'avoir";
      const isAvoirFinancier = this.facture.motif_avoir === 'ristourne';

      return !isAvoirFacture || (isAvoirFacture && isAvoirFinancier);
    },
    computedCheckReglement() {
      return this.facture.type !== "Facture d'avoir";
    },
    computedTypeReglement() {
      var table = this.TypeReglement.map(item => {
        return {
          text: item.name,
          value: item.id
        };
      });
      return [...table];
    },
    computedConditionPaiement() {
      var table = this.ConditionPaiement.map(item => {
        return {
          text: item.name,
          value: item.id
        };
      });
      return table;
    },
    computedPermissionType() {
      let typeToDisplay = ['Facture', 'Facture proforma', 'Devis'];
      // if (this.checkPermission('FLAVF') || this.checkPermission('FLCAPT')) {
      //   typeToDisplay.push("Facture d'avoir");
      // }
      return typeToDisplay;
    },
    formateValueFacture() {
      return function(data) {
        return data
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          .replaceAll(',', ' ');
      };
    },
    totalQteForAvoir() {
      let sum = 0;
      if (this.computedCheckTypeFactureAvoir) {
        this.facture?.produits?.forEach(element => {
          if (element.qte_apres != null) {
            sum += parseFloat(element.qte_apres || 0);
          }
        });
      }
      return sum;
    },
    NetHt() {
      let sum = 0.0;
      if (this.computedCheckTypeFactureNotAvoir) {
        this.facture?.produits?.forEach(element => {
          sum += parseFloat(element.total_ht || 0);
        });
      } else {
        this.facture?.produits?.forEach(element => {
          sum += parseFloat(element.total_ht_apres || 0);
        });
      }
      return sum;
    },
    totalHt() {
      let sum = 0.0;
      if (this.computedCheckTypeFactureNotAvoir) {
        this.facture?.produits?.forEach(element => {
          sum +=
            parseFloat((element.qte + '').replace(',', '.') || 0) *
            (element.unite === '%'
              ? parseFloat((element.pu_ht + '').replace(',', '.') || 0) / 100
              : parseFloat((element.pu_ht + '').replace(',', '.') || 0));
        });
      } else {
        this.facture?.produits?.forEach(element => {
          if (element.qte_apres != null && element.pu_ht_apres != null)
            sum +=
              parseFloat((element.qte_apres + '').replace(',', '.') || 0) *
              (element.unite === '%'
                ? parseFloat(
                    (element.pu_ht_apres + '').replace(',', '.') || 0
                  ) / 100
                : parseFloat(
                    (element.pu_ht_apres + '').replace(',', '.') || 0
                  ));
        });
      }
      return sum;
    },
    total_tva() {
      let sum = 0.0;
      if (this.computedCheckTypeFactureNotAvoir) {
        this.facture?.produits?.forEach(element => {
          sum +=
            Math.round(
              ((parseFloat((element.total_ht + '').replace(',', '.') || 0) *
                parseFloat((element.tva + '').replace(',', '.') || 0)) /
                100) *
                100
            ) / 100;
          // sum += parseFloat((element.total_ht * element.tva) / 100) || 0;
        });
      } else {
        this.facture?.produits?.forEach(element => {
          sum +=
            Math.round(
              ((parseFloat(
                (element.total_ht_apres + '').replace(',', '.') || 0
              ) *
                parseFloat((element.tva_apres + '').replace(',', '.') || 0)) /
                100) *
                100
            ) / 100;
        });
      }
      return sum;
    },
    totalTtc() {
      let sum = 0.0;
      if (this.computedCheckTypeFactureNotAvoir) {
        this.facture?.produits?.forEach(element => {
          sum += parseFloat((element.total_ttc + '').replace(',', '.') || 0);
        });
      } else {
        this.facture?.produits?.forEach(element => {
          if (element.total_ttc_apres != null) {
            sum += parseFloat(
              (element.total_ttc_apres + '').replace(',', '.') || 0
            );
          }
        });
      }
      return sum;
    },
    computedSumNetHtBeforSt() {
      return function(index) {
        let sum = 0.0;
        for (let i = index - 1; i >= 0; i--) {
          if (this?.facture?.produits[i]?.type == 'produit') {
            sum += this.facture.produits[i].total_ht;
          } else {
            return Math.round(sum * 100) / 100;
          }
        }
        return Math.round(sum * 100) / 100;
      };
    },
    totalReduction() {
      let sum = 0.0;
      if (this.computedCheckTypeFactureNotAvoir) {
        if (this.facture.comment_calculer_facture !== 'montant') {
          this.facture?.produits?.forEach(element => {
            sum +=
              (parseFloat((element.qte + '').replace(',', '.') || 0) *
                (element.unite === '%'
                  ? parseFloat((element.pu_ht + '').replace(',', '.') || 0) /
                    100
                  : parseFloat((element.pu_ht + '').replace(',', '.') || 0)) *
                parseFloat((element.reduction + '').replace(',', '.') || 0)) /
              100;
          });
        } else {
          this.facture?.produits?.forEach(element => {
            element.unite === '%'
              ? (sum +=
                  (parseFloat((element.reduction + '').replace(',', '.') || 0) /
                    100) *
                  parseFloat((element.qte + '').replace(',', '.') || 0))
              : (sum +=
                  parseFloat((element.reduction + '').replace(',', '.') || 0) *
                  parseFloat((element.qte + '').replace(',', '.') || 0));
          });
        }
      } else {
        if (this.facture.comment_calculer_facture !== 'montant') {
          this.facture?.produits?.forEach(element => {
            if (
              element.qte_apres != null &&
              element.pu_ht_apres != null &&
              element.reduction_apres != null
            ) {
              sum +=
                (parseFloat((element.qte_apres + '').replace(',', '.') || 0) *
                  (element.unite === '%'
                    ? parseFloat(
                        (element.pu_ht_apres + '').replace(',', '.') || 0
                      ) / 100
                    : parseFloat(
                        (element.pu_ht_apres + '').replace(',', '.') || 0
                      )) *
                  parseFloat(
                    (element.reduction_apres + '').replace(',', '.') || 0
                  )) /
                100;
            }
          });
        } else {
          this.facture?.produits?.forEach(element => {
            if (element.reduction_apres != null) {
              element.unite === '%'
                ? (sum +=
                    (parseFloat(
                      (element.reduction_apres + '').replace(',', '.') || 0
                    ) /
                      100) *
                    parseFloat((element.qte_apres + '').replace(',', '.') || 0))
                : (sum +=
                    parseFloat(
                      (element.reduction_apres + '').replace(',', '.') || 0
                    ) *
                    parseFloat(
                      (element.qte_apres + '').replace(',', '.') || 0
                    ));
            }
          });
        }
      }
      return sum;
    }
  },
  methods: {
    ...mapActions([
      'getFactureFilterForAvoir',
      'displayFactureTemplate',
      'getAllTemplateSociete',
      'getSettingFilialeTh',
      'addNewFactureLibreTh',
      'getAllCountreies',
      'fetchAllProducts',
      'getOneFacturesLibres',
      'displayFacture',
      'getFilialsOfConnectedResponsable',
      'addUniteTva',
      'getUnites',
      // 'getTvas',
      'getCategoriesFactureLibre',
      'addCategorie',
      'getAllErrorsCreationFactureLibre',
      'getAllRibOfFiliale',
      'fetchAllTypeSociete',
      'uploadFileLibre',
      'getAllTypesReglement',
      'getAllConditionsPaiement',
      'addNewClientPassage',
      'transformFormatObjetClientPassage'
    ]),
    acheteurSelectedPassage() {
      let item = this.societePassage.filter(
        element => element.id === this.facture.acheteur.filiale_id
      )[0];
      this.facture.acheteur.filiale_id = item?.id;
      this.facture.acheteur.nom_compagnie = item?.name;
      this.facture.acheteur.civilite = item?.civilite;
      this.facture.acheteur.nom_famille = item?.nom_famille;
      this.facture.acheteur.siren_tva = item?.siren_tva;
      this.facture.acheteur.siren_value = item?.siren_value;
      this.facture.acheteur.tva_value = item?.tva_value;
      this.facture.acheteur.adresse = item?.adresse;
      this.facture.acheteur.code_postal = item?.code_postal;
      this.facture.acheteur.ville = item?.ville;
      this.facture.acheteur.pays = item?.pays;
      this.facture.acheteur.telephone = item?.telephone;
      this.facture.acheteur.code_postal = item?.code_postal;
      this.facture.date_limite_reglement = item?.payment_condition;
      this.facture.acheteur.pays = item?.pays;
      this.facture.acheteur.email = item?.email;
      this.facture.acheteur.telephone = item?.telephone;
      this.facture.acheteur.telephone_portable = item?.telephone_portable;
      this.facture.acheteur.description = item?.description;
      this.facture.acheteur.type_societe = item?.type_societe;
      this.facture.acheteur.siret = item?.siret;
      this.facture.acheteur.siren_tva = 'Numéro TVA';
    },
    handleSubmitPassage() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.addNewClientPassage(this.passageToAdd)
          .then(response => {
            if (response && response.error) {
              this.error = response.error;
              this.loading = false;
            }
            if (response.success) {
              this.error = null;
              this.transformFormatObjetClientPassage(response.response);
              this.societePassage = this.getSettingFilialesTh.filter(
                element => element.type === 'passage'
              );
              this.facture.acheteur.id = response.response.id;
              this.acheteurSelectedPro();
              this.addNewPassage('hide');
              this.loading = false;
            }
          })
          .catch();
      }
    },
    addNewPassage(action) {
      if (action == 'show') {
        this.modalClientPassage = true;
      } else {
        this.modalClientPassage = false;
        this.$refs.form.reset();
        this.passageToAdd = {
          name: null,
          siren_tva: 'Numéro TVA',
          siren_value: null,
          tva_value: null,
          adresse: null,
          code_postal: null,
          pays: null,
          email: null,
          siret: null,
          telephone: null,
          telephone_portable: null,
          description: null,
          ville: null,
          type: 'passage'
        };
      }
    },
    ModalShowFacture(action) {
      if (action === 'show') {
        this.showFacture = true;
      } else if (action === 'hide') {
        this.showFacture = false;
        this.pdfSource = '';
      }
    },
    ModalAddCategorie(action) {
      if (action === 'show') {
        this.modalAddCategorie = true;
      } else if (action === 'hide') {
        this.modalAddCategorie = false;
        this.resteCategorieModal();
      }
    },
    ModalAddUnite(action) {
      if (action === 'show') {
        this.modalAddUnite = true;
      } else if (action === 'hide') {
        this.$refs.addUnite.reset();
        this.modalAddUnite = false;
        this.resteTvaUniteModal();
      }
    },
    changeText(event, index) {
      this.facture.produits[index].description = event;
    },
    changeLigneText(event, index) {
      this.facture.produits[index].contenu = event;
    },
    changeTextDescription(event) {
      this.facture.description = event;
    },
    changeTextObjet(event) {
      this.facture.objet = event;
    },
    resetModal() {
      this.passageToAdd = {
        name: null,
        siren_tva: 'Numéro TVA',
        siren_value: null,
        tva_value: null,
        adresse: null,
        code_postal: null,
        pays: null,
        email: null,
        siret: null,
        telephone: null,
        telephone_portable: null,
        description: null,
        ville: null,
        type: 'passage'
      };
      this.error = null;
    },
    handleSubmitClient() {
      if (this.$refs.formClientPassage.validate()) {
        this.loading = true;
        this.addNewClientPassage(this.passageToAdd)
          .then(response => {
            if (response && response.error) {
              this.error = response.error;
              this.loading = false;
            }
            if (response.success) {
              this.error = null;
              this.transformFormatObjetClientPassage(response.response);
              this.passageClient = this.getSettingFilialesTh.filter(
                element => element.type === 'passage'
              );
              this.facture.acheteur.filiale_id = response.response.id;
              if (this.facture.acheteur.type == 'passage') {
                this.acheteurSelectedPassage();
              } else {
                this.acheteurSelectedPro();
              }
              this.hideModal('addClientModal');
              this.loading = false;
            }
          })
          .catch();
      }
    },

    hideModal(ref) {
      this[ref] = false;
      this.resetModal();
      this.loading = false;
      this.$refs.formClientPassage.reset();
    },
    deleteUploadFile(index) {
      this.files.splice(index, 1);
    },
    async addCat() {
      if (this.nom_categorie === '' || this.nom_categorie === null) {
        this.errorCategorie = 'ce champ et obligatoire';
        return;
      } else {
        this.loadingCategorie = true;
        const response = await this.addCategorie({
          nom_categorie: this.nom_categorie
        });
        if (response.success) {
          this.ModalAddCategorie('hide');
          this.resteCategorieModal();
          this.loadingCategorie = false;
        } else {
          this.errorCategorie = response.error;
          this.loadingCategorie = false;
        }
      }
    },
    resteCategorieModal() {
      this.nom_categorie = null;
      this.errorCategorie = null;
    },
    onChangeTypeAvoir() {
      if (this.computedCheckTypeFactureAvoir) {
        const { motif_avoir } = this.facture;
        this.facture?.produits?.forEach(item => {
          if (motif_avoir === 'retour_avoir') {
            item.qte_apres = 0;
            item.pu_ht_apres = item.pu_ht_apres_avoir;
            item.reduction_apres = item.reduction;
          } else if (motif_avoir === 'avoir_financier') {
            item.qte_apres = item.qte_apres_avoir;
            item.pu_ht_apres = 0;
            item.reduction_apres = item.reduction;
          } else {
            item.qte_apres = item.qte;
            item.pu_ht_apres = item.pu_ht;
            item.reduction_apres = 0;
          }
        });
      }
    },
    resteTvaUniteModal() {
      this.errorTvaUnite = null;
    },

    addTvaAction(data) {
      this.getAllTvas.push(data);
    },
    async addUnite(type) {
      if (this.$refs.addUnite.validate()) {
        this.errorTvaUnite = '';
        this.loadingTvaUnite = true;
        const response = await this.addUniteTva({
          valeur: this.valeur,
          type: type
        });
        if (response.success) {
          this.ModalAddUnite('hide');
          this.resteTvaUniteModal();
          this.loadingTvaUnite = false;
        } else {
          this.$refs['addUnitModal'].hide();
          this.loadingTvaUnite = false;
          this.errorTvaUnite = response.response;
        }
      }
    },
    async filterResultsNumDoc() {
      this.isOpenDocumentList = true;
      const numeroDocumentCorrige = this.facture?.numero_document_corrige;
      if (numeroDocumentCorrige !== '') {
        let payload = {
          search: numeroDocumentCorrige
        };
        const response = await this.getFactureFilterForAvoir(payload);
        this.resultsListAvoir = response.success ? response.response : [];
      } else {
        this.resultsListAvoir = [];
      }
    },
    filterResultsProduit(produit, index, type) {
      this.focusInput = index;
      if (type == 'apres') {
        this.facture.produits[index].isOpenProduitList_apres = true;
      } else {
        this.facture.produits[index].isOpenProduitList = true;
      }
      this.resultsProduits = this.computedProducts.filter(item => {
        return item?.toLowerCase().startsWith(produit.toLowerCase());
      });
    },
    onChangeGlobalReduction(reductionGlobal) {
      const { produits, comment_calculer_facture } = this.facture;

      const calculateTVA = (total_ht, tva) => {
        const tvaAmount = Math.round(((total_ht * tva) / 100) * 100) / 100;
        return tvaAmount;
      };

      produits.forEach(item => {
        item.reduction = reductionGlobal;
        const qte = parseFloat((item.qte + '').replace(',', '.') || 0);
        const pu_ht = parseFloat((item.pu_ht + '').replace(',', '.') || 0);
        const unite =
          item.unite === '%'
            ? parseFloat((item.pu_ht / 100 + '').replace(',', '.') || 0)
            : pu_ht;

        if (comment_calculer_facture === 'pourcentage') {
          const total_ht =
            Math.round(
              (qte * unite - (qte * unite * reductionGlobal) / 100) * 100
            ) / 100;
          const tva = calculateTVA(
            total_ht,
            parseFloat((item.tva + '').replace(',', '.') || 0)
          );
          item.total_ht = total_ht;
          item.total_ttc_apres = Math.round((total_ht + tva) * 100) / 100;
        } else {
          const total_ht =
            Math.round(qte * (unite - reductionGlobal) * 100) / 100;
          const tva = calculateTVA(
            total_ht,
            parseFloat((item.tva + '').replace(',', '.') || 0)
          );
          item.total_ht = total_ht;
          item.total_ttc_apres = Math.round((total_ht + tva) * 100) / 100;
        }
      });
    },
    onChangeCommentCalculer() {
      const calculateTotalHT = item => {
        if (item.unite === '%') {
          return (
            parseFloat((item.qte + '').replace(',', '.') || 0) *
              parseFloat((item.pu_ht / 100 + '').replace(',', '.') || 0) -
            (parseFloat((item.qte + '').replace(',', '.') || 0) *
              parseFloat((item.pu_ht / 100 + '').replace(',', '.') || 0) *
              parseFloat((item.reduction + '').replace(',', '.') || 0)) /
              100
          );
        } else {
          return (
            parseFloat((item.qte + '').replace(',', '.') || 0) *
              parseFloat((item.pu_ht + '').replace(',', '.') || 0) -
            (parseFloat((item.qte + '').replace(',', '.') || 0) *
              parseFloat((item.pu_ht + '').replace(',', '.') || 0) *
              parseFloat((item.reduction + '').replace(',', '.') || 0)) /
              100
          );
        }
      };
      const calculateTotalTTC = (item, totalHT) => {
        let tva =
          (parseFloat((totalHT + '').replace(',', '.') || 0) *
            parseFloat((item.tva + '').replace(',', '.') || 0)) /
          100;

        return parseFloat((totalHT + '').replace(',', '.') || 0) + tva;
      };
      this.facture.produits.forEach(item => {
        let totalHT = 0;
        let totalTTC = 0;

        if (this.facture.comment_calculer_facture === 'pourcentage') {
          totalHT = Math.round(calculateTotalHT(item) * 100) / 100;
          totalTTC = Math.round(calculateTotalTTC(item, totalHT) * 100) / 100;
        } else {
          totalHT = Math.round(calculateTotalHT(item) * 100) / 100;
          totalTTC = Math.round(calculateTotalTTC(item, totalHT) * 100) / 100;
        }
        item.total_ht = totalHT;
        item.total_ttc_apres = totalTTC;
      });
    },
    onClickOutsideNumDoc() {
      this.isOpenDocumentList = false;
    },
    onClickOutsideProduct() {
      if (this.focusInput != null) {
        this.facture.produits[this.focusInput].isOpenProduitList = false;
        this.facture.produits[this.focusInput].isOpenProduitList_apres = false;
        this.focusInput = null;
      }
    },
    async setResultsListAvoir(num) {
      this.facture.numero_document_corrige = num;
      this.disabledNumFactureAvoir = true;
      this.showReduc = true;
      this.idAvoir = this.resultsListAvoir.find(
        element => element.num === num
      )?.id;
      const response = await this.getOneFacturesLibres(this.idAvoir);
      if (response.success) {
        let fact = this.getFactureLibreToUpdate;
        fact.categorie_id = this.getFactureLibreToUpdate?.categorie?.id;
        fact.type = "Facture d'avoir";
        fact.motif_avoir = 'retour_avoir';
        fact.isOpenProduitList = false;
        fact.isOpenProduitList_apres = false;
        fact.mode_reglement = parseInt(
          this.getFactureLibreToUpdate?.mode_reglement
        );
        fact.date_limite_reglement = parseInt(
          this.getFactureLibreToUpdate?.date_limite_reglement
        );
        fact.numero_document_corrige = this.facture.numero_document_corrige;
        fact.vendeur.id = this.getFactureLibreToUpdate?.vendeur?.filiale_id;
        fact.acheteur.id = this.getFactureLibreToUpdate?.acheteur?.filiale_id;
        fact.num = null;
        this.minDateForAvoir = this.getFactureLibreToUpdate?.date_creation;
        (fact.date_creation = moment(Date.now()).format('YYYY-MM-DD')),
          (this.facture = fact);
        this.facture.produits = this.facture.produits.map(item => {
          if (item.type == 'produit') {
            return {
              ...item,
              contenu_apres: '',
              nom_apres: item.nom,
              reference_apres: item.reference,
              depot_apres: null,
              reduction_apres:
                fact.motif_avoir === 'avoir_financier' ? 0 : item.reduction,
              qte_apres:
                fact.motif_avoir === 'retour_avoir' ||
                fact.motif_avoir === 'avoir_financier'
                  ? 0
                  : item.qte,
              unite_apres: item.unite,
              tva_apres: item.tva,
              pu_ht_apres:
                fact.motif_avoir === 'avoir_financier'
                  ? 0
                  : item.pu_ht_apres_avoir,
              total_ttc_apres: 0,
              total_ht_apres: 0,
              isSelectInputTva_apres: false,
              isSelectInput_apres: true,
              index_apres: 0,
              isOpenProduitList_apres: false
            };
          }
          return item;
        });
      }
    },
    setResultsProduct(result, produit) {
      produit.nom = result;
      produit.nom_apres = result;

      const currentProduit = this.getProduits.find(
        element => element.nom === result
      );
      if (currentProduit) {
        const puHt = parseFloat(currentProduit.pu_ht.replace(',', '.')) || 0;
        const tva = parseFloat(currentProduit.tva.replace(',', '.')) || 0;
        const qte = parseFloat((produit.qte + '').replace(',', '.')) || 0;

        produit.reference = currentProduit.reference;
        produit.pu_ht = puHt;
        produit.tva = tva;
        produit.total_ttc =
          Math.round((puHt + (puHt / 100) * tva) * qte * 100) / 100;
        produit.total_ht = Math.round(puHt * qte * 100) / 100;

        produit.reference_apres = currentProduit.reference;
        produit.pu_ht_apres = puHt;
        produit.tva_apres = tva;
        produit.total_ttc_apres =
          Math.round((puHt + (puHt / 100) * tva) * qte * 100) / 100;
        produit.total_ht_apres = Math.round(puHt * qte * 100) / 100;
      }
    },
    changeUnite(produit) {
      const qte = parseFloat((produit.qte + '').replace(',', '.')) || 0;
      const puHt = parseFloat((produit.pu_ht + '').replace(',', '.')) || 0;
      const reduction =
        parseFloat((produit.reduction + '').replace(',', '.')) || 0;
      const tva = parseFloat((produit.tva + '').replace(',', '.')) || 0;

      let totalHt;
      let tvaAmount;

      if (this.facture.comment_calculer_facture === 'pourcentage') {
        totalHt =
          Math.round(
            (qte * (produit.unite === '%' ? puHt / 100 : puHt) -
              (qte * (produit.unite === '%' ? puHt / 100 : puHt) * reduction) /
                100) *
              100
          ) / 100;
        tvaAmount = Math.round(((totalHt * tva) / 100) * 100) / 100;
      } else {
        totalHt =
          Math.round(
            qte * (produit.unite === '%' ? puHt - reduction : puHt) * 100
          ) / 100;
        tvaAmount = Math.round(((totalHt * tva) / 100) * 100) / 100;
      }

      produit.total_ht = totalHt;
      produit.total_ttc = Math.round((totalHt + tvaAmount) * 100) / 100;
    },
    calculTotalHtTtc(produit) {
      const qte = parseFloat((produit.qte + '').replace(',', '.')) || 0;
      const puHt = parseFloat((produit.pu_ht + '').replace(',', '.')) || 0;
      const reduction =
        parseFloat((produit.reduction + '').replace(',', '.')) || 0;
      const tva = parseFloat((produit.tva + '').replace(',', '.')) || 0;

      if (this.facture.comment_calculer_facture === 'pourcentage') {
        produit.total_ht =
          Math.round(
            (qte * (produit.unite === '%' ? puHt / 100 : puHt) -
              (qte * (produit.unite === '%' ? puHt / 100 : puHt) * reduction) /
                100) *
              100
          ) / 100;
        produit.total_ttc =
          Math.round(
            (produit.total_ht + (produit.total_ht * tva) / 100) * 100
          ) / 100;
      } else {
        produit.total_ht =
          Math.round(
            qte *
              (produit.unite === '%'
                ? (puHt - reduction) / 100
                : puHt - reduction) *
              100
          ) / 100;
        produit.total_ttc =
          Math.round(
            (produit.total_ht + (produit.total_ht * tva) / 100) * 100
          ) / 100;
      }
    },
    change_reduction(produit) {
      const qte = parseFloat((produit.qte + '').replace(',', '.')) || 0;
      const puHt = parseFloat((produit.pu_ht + '').replace(',', '.')) || 0;
      const reduction =
        parseFloat((produit.reduction + '').replace(',', '.')) || 0;
      const tva = parseFloat((produit.tva + '').replace(',', '.')) || 0;

      let totalHt;
      let tvaAmount;

      if (this.facture.comment_calculer_facture === 'pourcentage') {
        totalHt =
          Math.round(
            (qte * (produit.unite === '%' ? puHt / 100 : puHt) -
              (qte * (produit.unite === '%' ? puHt / 100 : puHt) * reduction) /
                100) *
              100
          ) / 100;
        tvaAmount = Math.round(((totalHt * tva) / 100) * 100) / 100;
      } else {
        totalHt =
          Math.round(
            qte * (produit.unite === '%' ? puHt - reduction : puHt) * 100
          ) / 100;
        tvaAmount = Math.round(((totalHt * tva) / 100) * 100) / 100;
      }

      produit.total_ht = totalHt;
      produit.total_ttc = Math.round((totalHt + tvaAmount) * 100) / 100;
    },
    change_reduction_apres(produit) {
      const qteApres =
        parseFloat((produit.qte_apres + '').replace(',', '.')) || 0;
      const puHtApres =
        parseFloat((produit.pu_ht_apres + '').replace(',', '.')) || 0;
      const reductionApres =
        parseFloat((produit.reduction_apres + '').replace(',', '.')) || 0;
      const unite = produit.unite;
      const tvaApres = produit.tva_apres;

      if (this.facture.comment_calculer_facture === 'pourcentage') {
        produit.total_ht_apres =
          Math.round(
            (qteApres * (unite === '%' ? puHtApres / 100 : puHtApres) -
              (qteApres *
                (unite === '%' ? puHtApres / 100 : puHtApres) *
                reductionApres) /
                100) *
              100
          ) / 100;

        const tva =
          Math.round(((produit.total_ht_apres * (tvaApres || 0)) / 100) * 100) /
          100;

        produit.total_ttc_apres =
          Math.round((produit.total_ht_apres + tva) * 100) / 100;
      } else {
        const netHtApresAvoir =
          parseFloat((produit.net_ht_apres_avoir + '').replace(',', '.')) || 0;
        const maxPuHt = puHtApres - reductionApres;

        produit.max_pu_ht = parseFloat(
          (netHtApresAvoir / qteApres + reductionApres).toFixed(2)
        );
        produit.total_ht_apres =
          Math.round(
            qteApres * (unite === '%' ? maxPuHt / 100 : maxPuHt) * 100
          ) / 100;

        const tva =
          Math.round(
            ((parseFloat((produit.total_ht + '').replace(',', '.')) *
              (parseFloat((produit.tva + '').replace(',', '.')) || 0)) /
              100) *
              100
          ) / 100;

        produit.total_ttc_apres =
          Math.round((produit.total_ht_apres + tva) * 100) / 100;
      }
    },
    calculTotalHtTtc_apres(produit) {
      const qteApres =
        parseFloat((produit.qte_apres + '').replace(',', '.')) || 0;
      const puHtApres =
        parseFloat((produit.pu_ht_apres + '').replace(',', '.')) || 0;
      const reductionApres =
        parseFloat((produit.reduction_apres + '').replace(',', '.')) || 0;
      const tvaApres =
        parseFloat((produit.tva_apres + '').replace(',', '.')) || 0;

      let totalHtApres;
      let tvaAmountApres;

      if (this.facture.comment_calculer_facture === 'pourcentage') {
        totalHtApres =
          Math.round(
            (qteApres * (produit.unite === '%' ? puHtApres / 100 : puHtApres) -
              (qteApres *
                (produit.unite === '%' ? puHtApres / 100 : puHtApres) *
                reductionApres) /
                100) *
              100
          ) / 100;
        tvaAmountApres =
          Math.round(((totalHtApres * tvaApres) / 100) * 100) / 100;
      } else {
        produit.max_pu_ht =
          parseFloat(produit.net_ht_apres_avoir / qteApres) + reductionApres;
        totalHtApres =
          Math.round(
            qteApres *
              (produit.unite === '%' ? puHtApres - reductionApres : puHtApres) *
              100
          ) / 100;
        tvaAmountApres =
          Math.round(((totalHtApres * tvaApres) / 100) * 100) / 100;
      }

      produit.total_ht_apres = totalHtApres;
      produit.total_ttc_apres =
        Math.round((totalHtApres + tvaAmountApres) * 100) / 100;
    },
    acheteurSelectedParticulier() {
      let item = this.societeParticulier.find(
        element => element.id === this.facture.acheteur.filiale_id
      );
      this.facture.acheteur.filiale_id = item?.id;
      this.facture.acheteur.nom_compagnie =
        item?.prenom + ' ' + item?.nom_famille;
      this.facture.acheteur.civilite = item?.civilite;
      this.facture.acheteur.nom_famille = item?.nom_famille;
      this.facture.acheteur.siren_tva = item?.siren_tva;
      this.facture.acheteur.siren_value = item?.siren_value;
      this.facture.acheteur.adresse = item?.adresse;
      this.facture.acheteur.code_postal = item?.code_postal;
      this.facture.acheteur.ville = item?.ville;
      this.facture.acheteur.pays = item?.pays;
      this.facture.acheteur.telephone = item?.telephone;
      this.facture.acheteur.code_postal = item?.code_postal;
      // this.facture.acheteur.ville = item?.ville;
      this.facture.date_limite_reglement = item?.payment_condition;
      this.facture.acheteur.pays = item?.pays;
      this.facture.acheteur.email = item?.email;
      this.facture.acheteur.telephone = item?.telephone;
      this.facture.acheteur.telephone_portable = item?.telephone_portable;
      this.facture.acheteur.description = item?.description;
      this.facture.acheteur.type_societe = item?.type_societe;
      this.facture.acheteur.siret = item?.siret;
      this.facture.acheteur.siren_tva = 'Numéro TVA';
    },
    acheteurSelectedPro() {
      const table =
        this.facture.acheteur.type === 'passage'
          ? this.societePassage
          : this.societeProfessionel;
      const item = table.find(
        element => element.id === this.facture.acheteur.filiale_id
      );
      if (item) {
        this.facture.acheteur.filiale_id = item.id;
        this.facture.acheteur.nom_compagnie = item.name;
        this.facture.acheteur.civilite = item.civilite;
        this.facture.acheteur.nom_famille = item.nom_famille;
        this.facture.acheteur.siren_value = item.siren_value;
        this.facture.acheteur.tva_value = item.tva_value;
        this.facture.acheteur.adresse = item.adresse;
        this.facture.acheteur.code_postal = item.code_postal;
        this.facture.acheteur.ville = item.ville;
        this.facture.acheteur.pays = item.pays;
        this.facture.acheteur.telephone = item.telephone;
        this.facture.acheteur.code_postal = item.code_postal;
        this.facture.acheteur.pays = item.pays;
        this.facture.acheteur.email = item.email;
        this.facture.acheteur.telephone = item.telephone;
        this.facture.date_limite_reglement = item.payment_condition;
        this.facture.acheteur.telephone_portable = item.telephone_portable;
        this.facture.acheteur.description = item.description;
        this.facture.acheteur.type_societe = item.type_societe;
        this.facture.acheteur.siret = item.siret;
        this.facture.acheteur.siren_tva = 'Numéro TVA';
      }
    },
    async vendeurSelected() {
      let item = this.getFiliaeOfResponsable.find(
        element => element.id === this.facture.vendeur.id
      );

      this.getAllTvas = item.tva;
      this.facture.vendeur.filiale_id = item.id;
      this.facture.vendeur.nom_compagnie = item.name;
      this.facture.vendeur.email = item.email;
      this.facture.vendeur.adresse = item.adresse;
      this.facture.vendeur.iban = item.iban;
      this.facture.vendeur.swift = item.swift;
      this.facture.vendeur.site_internet = item.site_internet;
      this.facture.vendeur.fax = item.fax;
      this.facture.vendeur.telephone = item.telephone;
      this.facture.vendeur.code_postal = item.code_postal;
      this.facture.vendeur.ville = item.ville;
      this.facture.vendeur.pays = item.pays;
      this.facture.mode_reglement = item.payment_type?.id;
      this.facture.vendeur.description = item.description;
      this.facture.vendeur.type_societe =
        item.type === 'particulier' ? null : item.type_societe;
      this.facture.vendeur.siret = item.siret;
      this.facture.vendeur.siren_value = item.siren_value;
      this.facture.vendeur.tva_value = item.tva_value;
      await this.getAllRibOfFiliale(item.id);
      this.rib = null;
      this.facture.iban = null;
      this.facture.swift = null;
      this.facture.bank = null;

      const ribWithLibreFamille = this.getRibOfFiliale.find(rib =>
        rib.familles.some(item => item.famille === 'libre')
      );
      if (ribWithLibreFamille) {
        this.rib = ribWithLibreFamille;
        this.facture.iban = ribWithLibreFamille.iban;
        this.facture.swift = ribWithLibreFamille.swift;
        this.facture.bank = ribWithLibreFamille.bank;
      }
    },
    onChangeRibSelect(id) {
      const rib = this.getRibOfFiliale.find(item => item.id == id);
      this.facture.iban = rib.iban;
      this.facture.swift = rib.swift;
      this.facture.bank = rib.bank;
    },
    showMoreVendeur() {
      this.hideVendeur = !this.hideVendeur;
    },
    showMoreAcheteur() {
      this.hideAcheteur = !this.hideAcheteur;
    },
    showReduction() {
      this.showReduc = true;
    },
    showDepot() {
      this.showDep = true;
    },
    hideReduction() {
      this.showReduc = false;
    },
    hideDepot() {
      this.showDep = false;
    },
    addNewProduct() {
      this.facture.produits.push({
        contenu: '',
        nom: null,
        reference: null,
        depot: null,
        reduction: 0,
        qte: 1,
        unite: null,
        tva: 20,
        pu_ht: 0,
        total_ttc: 0,
        total_ht: 0,
        description: null,
        isSelectInputTva: false,
        isSelectInput: true,
        index: 0,
        type: 'produit',
        isOpenProduitList: false,
        contenu_apres: '',
        nom_apres: null,
        reference_apres: null,
        depot_apres: null,
        reduction_apres: 0,
        qte_apres: 1,
        unite_apres: ' ',
        tva_apres: 0,
        pu_ht_apres: 0,
        total_ttc_apres: 0,
        total_ht_apres: 0,
        isSelectInputTva_apres: false,
        isSelectInput_apres: true,
        index_apres: 0,
        isOpenProduitList_apres: false,
        qte_sous_total_avoir: 0,
        pu_ht_sous_total_avoir: 0,
        total_ht_sous_total_avoir: 0,
        total_ttc_sous_total_avoir: 0,
        adresse: null,
        prestation_marchandise: 'prestation'
      });
    },
    addNewSousTotal() {
      this.facture.produits.push({
        qte: 0,
        unite: null,
        reduction: 0,
        tva: 0,
        pu_ht: 0,
        total_ttc: 0,
        total_ht: 0,
        index: 0,
        type: 'sous_total',
        contenu: '',
        index: 0
      });
    },
    addNewSautPage() {
      this.facture.produits.push({
        qte: 0,
        reduction: 0,
        unite: null,
        tva: 0,
        pu_ht: 0,
        total_ttc: 0,
        total_ht: 0,
        index: 0,
        type: 'saut_page',
        contenu: '',
        index: 0,
        contenu: ''
      });
    },
    addNewTextLine() {
      this.facture.produits.push({
        qte: 0,
        unite: null,
        reduction: 0,
        tva: 0,
        pu_ht: 0,
        total_ttc: 0,
        total_ht: 0,
        index: 0,
        type: 'ligne_text',
        contenu: '',
        index: 0
      });
    },
    deleteProduct(index, type, produit) {
      if (type === 'ligne_text') {
        const indice = this.facture.produits.indexOf(produit);
        if (indice !== -1) {
          this.facture.produits.splice(indice, 1);
        }
      } else {
        this.facture.produits.splice(index, 1);
      }
    },
    async submitFactureLibre() {
      if (this.totalTtc < this.facture.montant_payer) {
        this.errorValidation = 'Acompte Payé ne doit pas dépasser le total TTC';
        return;
      }
      if (
        this.facture.produits.filter(
          p => p.type == 'produit' && p.prestation_marchandise == null
        ).length > 0
      ) {
        this.errorValidation = 'Type de produit obligatoire';
        return;
      }
      if (
        this?.facture?.vendeur?.filiale_id !== undefined &&
        this?.facture?.vendeur?.filiale_id !== null &&
        this.facture.date_creation !== null
      ) {
        this.disabledSaveButton = true;
        const res = await this.getAllErrorsCreationFactureLibre({
          type: this.facture.type,
          id: this?.facture?.vendeur?.filiale_id,
          date_creation: this.facture.date_creation
        });
        if (res.success) {
          this.testRetourErrors = true;
          this.disabledSaveButton = false;
        } else {
          this.testRetourErrors = false;
          this.disabledSaveButton = false;
          this.$swal.fire({
            // title: res.error,
            background: 'rgb(245 245 252)',
            type: 'error',
            icon: 'error',
            allowOutsideClick: false,
            showCloseButton: true,
            showConfirmButton: false,
            html:
              '<h5 id="text-error"><b>' +
              res.error +
              '</b></h5>' +
              " <p><centre> Veuillez contacter l'administrateur pour apporter les corrections nécessaires</centre></p>" +
              '<br>' +
              '' +
              '<div style="display:flex;justify-content:space-around">' +
              '<button  type="button" role="button" id="SwalBtn" tabindex="0" class="SwalBtn1 customSwalBtn" style=" border: 0;outline: 0;padding: 10px;border-radius: 9px;color: white;background: #f8be40;">' +
              'Essayer de nouveau' +
              '</button>' +
              '</div>',
            willOpen: () => {
              document
                .getElementById('SwalBtn')
                .addEventListener('click', async () => {
                  document.getElementById('SwalBtn').innerHTML =
                    'Essayer de nouveau' + '<div>Chargement ...</div>';
                  const response2 = await this.getAllErrorsCreationFactureLibre(
                    {
                      type: this?.facture?.type,
                      id: this?.facture?.vendeur?.filiale_id,
                      date_creation: this?.facture?.date_creation
                    }
                  );
                  if (response2.success) {
                    this.$swal.close();
                    this.testRetourErrors = true;
                    this.disabledSaveButton = false;
                  } else {
                    this.testRetourErrors = false;
                    this.disabledSaveButton = false;
                    if (this.checkPermission('GS')) {
                      var url = '/setting/gestion-filiale-th';
                      var win = window.open(url, '_blank');
                      win.myData = { nom: this.facture.vendeur.nom_compagnie };
                      win.focus();
                      // window.open('/setting/gestion-filiale-th', '_blank');
                    }
                    document.getElementById('text-error').innerHTML =
                      response2.error;
                    document.getElementById('SwalBtn').innerHTML =
                      'Essayer de nouveau';
                  }
                });
            }
          });
        }
      }
      if (this.testRetourErrors === true) {
        this.facture.famille = 'libre';
        this.facture.displayDiscription === true
          ? (this.facture.displayDiscription = 1)
          : (this.facture.displayDiscription = 0);
        if (this.totalHt <= 0 && this.totalReduction == 0.0) {
          this.$swal.fire({
            title: 'Erreur',
            text: 'On ne peut pas générer une facture avec total HT 0.',
            icon: 'error',
            confirButtonText: 'OK'
          });
        } else {
          this.$swal
            .fire({
              background: 'rgb(245 245 252)',
              title: 'Êtes vous sûre de bien vouloir enregistrer les données ?',
              type: 'warning',
              icon: 'warning',
              locale: 'fr',
              showLoaderOnConfirm: true,
              allowOutsideClick: false,
              showCloseButton: true,
              showConfirmButton: true,
              showCancelButton: true,
              html: '<div></div>',
              confirmButtonText: 'Oui',
              cancelButtonText: 'Non',
              confirmButtonClass: 'btn btn-success',
              cancelButtonClass: 'btn btn-danger',
              cancelButtonColor: '#d33',
              customClass: {
                actions: 'my-actions',
                cancelButton: 'order-2 ',
                confirmButton: 'order-1'
              },
              preConfirm: () => {
                this.$swal.showLoading();
              }
            })
            .then(async result => {
              if (result.isConfirmed) {
                this.loading = true;
                if (
                  this.facture.type === "Facture d'avoir" &&
                  this.facture.motif_avoir != 'ristourne'
                ) {
                  if (
                    this.facture &&
                    this.facture.produits &&
                    this.facture.produits.length
                  ) {
                    this.facture.produits = this.facture.produits.filter(
                      item =>
                        item.total_ttc_apres != 0 &&
                        item.total_ht_aprestotal_ht_apres != 0
                    );
                  }
                }
                const response = await this.addNewFactureLibreTh(this.facture);
                if (response.success === true) {
                  {
                    if (this.files.length != 0) {
                      let bodyFormData = new FormData();
                      bodyFormData.append('facture_id', response.response);
                      for (let i = 0; i < this.files?.length; i++) {
                        bodyFormData.append(
                          'files[' + i + '][file]',
                          this.files[i]
                        );
                        if (this.files[i]['description'] != undefined) {
                          bodyFormData.append(
                            'files[' + i + '][description]',
                            this.files[i]['description']
                          );
                        }
                      }
                      await this.uploadFileLibre({
                        bodyFormData: bodyFormData,
                        facture_id: response.response,
                        vue: false
                      });

                      this.$router.push(`/facture/${this.addFactureLibre.id}`);
                      this.loading = false;
                      this.disabledSaveButton = false;
                    } else {
                      this.$router.push(`/facture/${this.addFactureLibre.id}`);
                      this.loading = false;
                      this.disabledSaveButton = false;
                    }
                  }
                } else {
                  this.loading = false;
                  this.disabledSaveButton = false;
                }
              }
            });
        }
      }
    },
    async displayBill() {
      this.loadingDisplay = true;
      this.facture.famille = 'libre';
      if (this.facture.template_id == null) {
        const response = await this.displayFacture(this.facture);
        if (response.success === true) {
          this.ModalShowFacture('show');
          this.loadingDisplay = false;
          this.pdfSource = response.response;
        } else {
          this.error = response.error;
          this.loadingDisplay = false;
        }
      } else {
        let template = {
          id_template: this.facture.template_id,
          id_facture: null,
          facture: this.facture,
          action: 'apercu'
        };
        const response = await this.displayFactureTemplate(template);
        if (response.success === true) {
          this.pdfSource = response.response;
          this.ModalShowFacture('show');
          this.loadingDisplay = false;
        } else {
          this.error = response.error;
          this.loadingDisplay = false;
        }
      }
    },
    resetAcheteur() {
      this.facture.acheteur.civilite = 'M.';
      this.facture.acheteur_color = '#000000';
      this.facture.acheteur.nom_compagnie = null;
      this.facture.acheteur.name = null;
      this.facture.acheteur.siren_tva = 'Numéro TVA';
      this.facture.acheteur.siren_value = null;
      this.facture.acheteur.tva_value = null;
      this.facture.acheteur.type_societe = null;
      this.facture.acheteur.adresse = null;
      this.facture.acheteur.code_postal = null;
      this.facture.acheteur.ville = null;
      this.facture.acheteur.pays = null;
      this.facture.acheteur.email = null;
      this.facture.acheteur.telephone = null;
      this.facture.acheteur.telephone_portable = null;
      this.facture.acheteur.description = null;
      this.facture.acheteur.siret = null;
      this.facture.acheteur.prenom = null;
      this.facture.acheteur.nom_famille = null;
      this.facture.acheteur.iban = null;
      this.facture.acheteur.swift = null;
    }
  },

  async mounted() {
    await this.getAllConditionsPaiement();
    await this.getAllTypesReglement();
    await this.getSettingFilialeTh();
    await this.getFilialsOfConnectedResponsable();
    this.societeProfessionel = this.getSettingFilialesTh.filter(
      element =>
        element.type === 'professionnel' ||
        element.type === 'organisme' ||
        element.type === 'master filiale'
    );
    this.societePassage = this.getSettingFilialesTh.filter(
      element => element.type === 'passage'
    );
    this.societeParticulier = this.getSettingFilialesTh.filter(
      element => element.type === 'particulier'
    );
    this.societePassage?.map(item => this.filialsListNames?.push(item.name));
    this.particulierSocite?.map(item =>
      this.filialsListParticuluerNames?.push(
        item.prenom + ' ' + item.nom_famille
      )
    );
    await this.getUnites();
    await this.getCategoriesFactureLibre();
    await this.getAllCountreies();
    const response = await this.getOneFacturesLibres(this.$route?.params?.id);
    if (response.success === true) {
      this.facture = this.getFactureLibreToUpdate;
      this.getAllTvas = this.facture.vendeur.tva;
      if (
        this.getFactureLibreToUpdate.type === 'Facture proforma' ||
        this.getFactureLibreToUpdate.type === 'Devis'
      ) {
        this.facture.etat = 'Créé';
      }
      if (this.getFactureLibreToUpdate.type === 'Facture') {
        this.facture.etat = 'A payer';
      }
      if (this.getFactureLibreToUpdate.displayDiscription === 1) {
        this.facture.displayDiscription = true;
      } else {
        this.facture.displayDiscription = false;
      }
      this.getFactureLibreToUpdate.payment_condition === null
        ? (this.facture.date_limite_reglement = null)
        : (this.facture.date_limite_reglement = this.getFactureLibreToUpdate.payment_condition.id);
      this.facture.num = null;
      this.facture.num_proforma = null;
      this.facture.type_avoir = null;
      this.facture.acheteur.filiale_id = this.getSettingFilialesTh?.find(
        element =>
          element.id === this.getFactureLibreToUpdate.acheteur.filiale_id
      )?.id;
      this.facture.vendeur.idOrigine = this.getSettingFilialesTh?.find(
        element =>
          element.name === this.getFactureLibreToUpdate.vendeur.nom_compagnie
      )?.id;
      this.facture.date_creation = moment(Date.now()).format('YYYY-MM-DD');
      await this.getAllRibOfFiliale(this.facture.vendeur.idOrigine);
      if (this.getRibOfFiliale.length) {
        for (let i = 0; i < this.getRibOfFiliale.length; i++) {
          if (
            this.getRibOfFiliale[i].familles.some(
              item => item.famille === 'libre'
            )
          ) {
            this.rib = this.getRibOfFiliale[i];
            this.facture.iban = this.getRibOfFiliale[i].iban;
            this.facture.swift = this.getRibOfFiliale[i].swift;
            this.facture.bank = this.getRibOfFiliale[i].bank;
            break;
          }
        }
      } else {
        this.rib = null;
        this.facture.iban = null;
        this.facture.swift = null;
        this.facture.bank = null;
      }
      this.facture.categorie_id = this.getFactureLibreToUpdate?.categorie?.id;
      this.loading = false;
      await this.getAllTemplateSociete('libre');
      await this.fetchAllProducts();
      await this.fetchAllTypeSociete();
      let countryNames = [];
      this.getPaysList.map(element => countryNames.push(element.nom));
      this.uniqueArrayOfCountries = [...new Set(countryNames)];
      this.facture.acheteur.siren_tva = 'Numéro TVA';
      this.facture.vendeur.siren_tva = 'Numéro TVA';
    }
    this.facture?.produits?.forEach(element => {
      if (element.reduction != '0') {
        this.showReduc = true;
      }
      return;
    });
    this.loaderComponent = false;
  }
};
</script>
<style lang="scss">
.v-menu__content ::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 10px;
}
.v-menu__content ::-webkit-scrollbar-track {
  width: 7px;
  height: 7px;
  border-radius: 10px;
}
.v-menu__content ::-webkit-scrollbar-thumb {
  width: 7px;
  height: 7px;
  border-radius: 10px;
}
</style>

<style lang="scss" scoped>
.form-add {
  padding-top: 10px;
}

* {
  font-family: 'Montserrat', sans-serif;
}
.body-box-rapport {
  padding: 0px !important ;
  // height: calc(100vh - 68px);
  .table-rapport-style-societe {
    width: 48%;
  }
  .table-rapport-style-client {
    width: 48%;
    margin-left: 4%;
  }
  .table-rapport-style-type {
    width: 100%;
  }
  .width-table-rapport {
    width: 100%;
  }
}
.icon-plus {
  font-size: 15px;
  padding-top: -13px;
  /* margin-top: 4px; */
  margin-right: 5px;
  padding-right: -8px;
  width: 10px;
}

.contenu-facture-libre {
  padding: 0px;
  overflow: hidden;
  width: 100%;
  background-color: #f6f6f6;
}

.contenu-facture-libre ::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 10px;
}
.contenu-facture-libre ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f0eef8;
  border-radius: 10px;
}
.contenu-facture-libre ::-webkit-scrollbar-thumb {
  background: #704ad1;
  border-radius: 10px;
  cursor: pointer !important;
}

ul.autocomplete-results.list-unstyled.style_liste_avoir {
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  margin-top: -9px;
  padding-top: 10px;
  ul {
    padding-left: 12px;
  }
  li {
    margin-top: -7px !important;
    text-align: center;
    padding: 4px;
    cursor: pointer;
  }
}
ul.autocomplete-results.list-unstyled.style_liste_products {
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  width: 250px;
  margin-top: -9px;
  max-height: 125px;
  padding-top: 10px;
  ul {
    padding-left: 12px;
  }
  li {
    margin-top: -7px !important;
    text-align: center;
    padding: 4px;
    cursor: pointer;
  }
}
.row-radios {
  margin-bottom: -33px !important;
  margin-top: -33px !important;
}

.type-class {
  margin-top: 7px;
  font-size: 15px;
  padding-top: 11px;
  color: #00000099;
}

.v-messages.theme--light {
  display: none;
}
.mr-76 {
  margin-right: 76px;
}
button::before {
  background: none !important;
  background-color: #fff !important;
  transition: none !important;
  text-decoration: none !important;
}
.row-total {
  margin-top: -10px;
  margin-bottom: 0px;
}
.card-total {
  background: #f6f6f6;
}
.card-name {
  font-size: 16px;
  font-weight: bold;
  line-height: 12px;
  letter-spacing: 1px;
  color: #2f2f2f;
  padding-top: 3px !important;
}
.btn-product {
  border: none !important;
  background: none !important;
  box-shadow: none !important;
  font-weight: 600 !important;
  &:hover {
    background: none !important;
  }
}
.product-name {
  margin-top: 12px;
}
// .col-new-product {
//   margin-right: -150px;
// }
button.btn-product.v-btn.v-btn--has-bg.theme--light.v-size--default {
  background: none !important;
}
.header-content {
  padding-top: 18px !important;
  padding-left: 50px !important;
  padding-bottom: 18px !important;
  padding-right: 28px !important;
}
.col-new-saut {
  margin-left: -60px;
}
.col-new-total {
  margin-left: -60px;
}
// .col-new-line {
//   margin-right: -93px;
// }
.content-card {
  padding-top: 12px;
  padding-left: 50px;
  padding-right: 50px;
  background-color: #fff !important;
}

.card-style {
  border-radius: 10px;
  border: 1px solid #b5b5b5;
  padding: 28px;
  margin-top: 16px;
  margin-bottom: 20px;
}
.col-plus {
  max-width: 3% !important;
  padding-left: 0px !important;
}
.actionModel {
  text-align: center;

  .button-cancel-style {
    width: 100px;
  }
}
.more_less {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  font-family: 'Montserrat';
  width: 24%;
  font-size: 12px;
  letter-spacing: 0.15px;
  line-height: 12px;
  color: black;
  font-weight: 600;
}
.scroll-bar {
  overflow-y: scroll;
  overflow-x: hidden;
}

.autocomplete {
  position: relative;
}

.autocomplete-results {
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  height: 120px;
  overflow: auto;
}
.style_liste_avoir {
  position: absolute;
  background-color: #fff;
  z-index: 50;
  width: 343px;
  height: auto;
  max-height: 300px;
}

.style_liste_avoir::-webkit-scrollbar {
  width: 8px;
  background-color: #f1edfb;
}

.style_liste_avoir::-webkit-scrollbar-thumb {
  background-color: #f1edfb;
  border-radius: 10px;
}

.style_liste_avoir::-webkit-scrollbar-thumb:hover {
  background-color: #f1edfb;
}

.style_liste_avoir::-webkit-scrollbar-track {
  background-color: #f1edfb;
}
</style>
